import { createService } from "services/serviceFactory";
import { SignInRequest, SignUpRequest } from "./request";
import { SignInResponse, TokenRenewResponse } from "./response";

const authService = createService("auth");

async function signUp(data: SignUpRequest) {
  return authService.post("/auth/v1/Auth/Register",
    { email: data.email, returnUrl: data.returnUrl },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );
}

async function signIn(data: SignInRequest) {
  return authService.post<SignInResponse>("/auth/v1/Auth/Login", data);
}

async function signInEpToken(token: string, returnUrl?: string) {
  return authService.get<SignInResponse>("/auth/v1/Auth/Login-Ep", {
    params: { returnUrl },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function signInAd() {
  return authService.get<SignInResponse>("/auth/v1/Auth/Login-Ad",{
    withCredentials: true,
  });
}

async function checkSession(accessToken: string) {
  const resp = await authService.get("/auth/v1/Auth/CheckSession", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: () => true, //dont throw exception
  });
  return resp.status === 200;
}

async function renewToken(refreshToken: string) {
  return authService.post<TokenRenewResponse>("/auth/v1/Auth/RefreshTokens", {
    refreshToken,
  });
}

async function sendForgotPasswordEmail(email: string) {
  return authService.post("/auth/v1/Auth/SendForgotPasswordEmail", { email });
}

async function activateUserAccount(code: string) {
  return authService.post("/auth/v1/Auth/ActivateUserAccount", { code });
}

async function verifyResetPasswordCode(code: string) {
  return authService.post("/auth/v1/Auth/VerifyResetPasswordCode", { code });
}

async function resetPassword(code: string, password: string) {
  return authService.post("/auth/v1/Auth/ResetPassword", { code, password });
}

export {
  signIn,
  signUp,
  signInEpToken,
  signInAd,
  checkSession,
  renewToken,
  sendForgotPasswordEmail,
  activateUserAccount,
  verifyResetPasswordCode,
  resetPassword,
};
