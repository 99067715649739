import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
    isActivating: boolean;
    activationSuccess: boolean;
    activationError: boolean;
    errorMessage: string;
}

interface RequestError {
    errorMessage: string;
}

const initialState: State = {
    isActivating: false,
    activationError: false,
    activationSuccess: false,
    errorMessage: "",
};

const activateUserSlice = createSlice({
    name: "ActivateUser",
    initialState: initialState,
    reducers: {
        activateUserRequest: state => {
            state.isActivating = true;
        },
        activateUserSuccess: state => {
            state.isActivating = false;
            state.activationSuccess = true;
        },
        activateUserError: (state, action: PayloadAction<RequestError>) => {
            state.isActivating = false;
            state.activationError = true;
            state.errorMessage = action.payload.errorMessage;
        },
    },
});

const { actions } = activateUserSlice;

export const { activateUserRequest, activateUserSuccess, activateUserError } = actions;

export const activateUserReducer = activateUserSlice.reducer;
