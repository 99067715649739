import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { signInUser } from "../store";

export const useEpoljoprivredaLogin = () => {
    const dispatch = useDispatch();
    const [renewed, setRenewed] = useState(false);
    const isRenewing = useSelector((state: RootState) => state.signIn.isRenewing);

    useEffect(() => {
        const params = new URLSearchParams(window.location?.search);
        if (isRenewing) {
            setRenewed(true);
        } else if (params.has("token") && renewed) {
            const paramsObj = Object.fromEntries(params);
            dispatch(signInUser({ token: paramsObj.token, returnUrl: paramsObj.returnUrl }));
        }
    }, [window.location?.search, dispatch, isRenewing, renewed]);
};

export const useEpoljoprivredaData = () => {
    const params = new URLSearchParams(window.location?.search);
    return {
        token: params.get("token") ?? undefined,
        returnUrl: params.get("returnUrl") ?? undefined,
    };
};