import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForEmployeeMp = (
  abilityBuilder: AbilityBuilder<AppAbility>
) => {
  const { can, cannot, rules } = abilityBuilder;

  cannot("view", "CourseList");
  cannot("view", "FarmList");
  cannot("update", "UserRole");

  can("view", "InternalDocumentation");
  can("view", "PIO");
  can("view", "Webtop");
  can("view", "ReportProblem");
  can("view", "InternalSystemsModule");
  can("view", "Records");

  can("view", "WeatherAlarmToggle");
  can("view", "ExternalModules");


  return rules;
};
