import { Input, useTitle } from "modules/common";
import { Overlay } from "modules/common/components/Overlay";
import useDebounced from "modules/common/hooks/useDebounced";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

export const Advisors: React.FC = () => {
    useTitle("Savjetnici - eSavjetnik");
    const [searchText, setSearchText] = useState("");
    const debouncedSearch = useDebounced(searchText, 500);

    return (
        <Overlay
            search={
                <Input
                    id="search"
                    name="search"
                    errorMessage=""
                    type="text"
                    isValid
                    size="medium"
                    rightIcon={<SearchIcon />}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                />
            }
            fullScreenInitially
            view={{
                title: "Savjetnici",
                source: "savjetnici",
                parameters: { search: debouncedSearch },
            }}
        />
    );
};
