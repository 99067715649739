import { useEffect, useState } from "react";

export const useElementRendered = (selector: string) => {
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        const checkRendered = (interval: NodeJS.Timer) => {
            setRendered(r => {
                if (r) clearInterval(interval);
                return Boolean(document.querySelector(selector));
            });
        };

        let interval: NodeJS.Timer = setInterval(() => checkRendered(interval), 100);

        return () => {
            clearInterval(interval);
        };
    }, [selector, setRendered]);

    return rendered;
};
