import React from "react";
import { Row, TableBodyPropGetter, TableBodyProps } from "react-table";

interface Props {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object>
  ) => TableBodyProps;
  rows: Row<object>[];
  prepareRow: (row: Row<object>) => void;
}
export const TableBody: React.FC<Props> = ({
  getTableBodyProps,
  rows,
  prepareRow,
}) => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr className="" {...row.getRowProps()}>
            {row.cells.map((cell) => {
              //web column
              if (Number(cell.column.id) === 2) {
                return (
                  <td
                    className="table-data module-table-data"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              }
              //mobile column
              if (Number(cell.column.id) === 3) {
                return (
                  <td
                    className="table-data mobile-table-data"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              }

              return (
                <td className="table-data" {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
