import * as React from "react";
import { notificationTypes } from "modules/common";
import { useMemo } from "react";

interface Props {
    notificationType: string[];
}

export const Notifications: React.FC<Props> = ({ notificationType }) => {
    const notifications = useMemo(
        () => [
            {
                value: notificationTypes.Email.value,
                label: notificationTypes.Email.label,
            },
            {
                value: notificationTypes.SMS.value,
                label: notificationTypes.SMS.label,
            },
            {
                value: notificationTypes.Viber.value,
                label: notificationTypes.Viber.label,
            },
            {
                value: notificationTypes.WhatsApp.value,
                label: notificationTypes.WhatsApp.label,
            },
        ],
        []
    );

    return (
        <div className="notification-container">
            {notifications
                .filter(n => notificationType.includes(n.label))
                .map(({ label }, index) => (
                    <div key={index}>
                        <label className="notification-text">{label}</label>
                    </div>
                ))}
        </div>
    );
};
