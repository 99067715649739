import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { PanelMenu, PanelMenuItem } from "../PanelMenu";
import { Loading } from "../Loading";
import { OverlayTitleBarProps, useOverlayTitleBar } from "modules/common/hooks/useOverlayTitleBar";
import { OverlayViewProps, useOverlayView } from "modules/common/hooks/useOverlayView";
import { ReactComponent as ExpandFullScreenIcon } from "assets/icons/expand-fullscreen.svg";
import { ReactComponent as ShrinkFullScreenIcon } from "assets/icons/shrink-fullscreen.svg";
import { CategoryMessageDisplay } from "../CategoryMessageDisplay";

export interface OverlayState {
    previouslySelectedMenuItem?: PanelMenuItem;
    selectedMenuItem?: PanelMenuItem;
    fullScreen?: boolean;
    backButton?: boolean;
    isBackButtonVisible: boolean;
    showLoading: boolean;
    setFullScreen: Dispatch<SetStateAction<boolean>>;
    setIsBackButtonVisible: Dispatch<SetStateAction<boolean>>;
    setShowLoading: Dispatch<SetStateAction<boolean>>;
    changeSelectedMenuItem: (menuItemToChangeTo?: PanelMenuItem) => void;
}

interface Props {
    fullScreenInitially?: boolean;
    searchInFullScreen?: boolean;
    fullScreenButton?: boolean;
    backButton?: boolean;
    containerClass?: string;
    menuItems?: PanelMenuItem[];
    selectFirstMenuItemWhenAvailable?: boolean;
    search?: JSX.Element | null;
    titleBar?: OverlayTitleBarProps;
    view?: OverlayViewProps;
    onMenuItemChanged?: (item: PanelMenuItem) => void;
}

export const Overlay: React.FC<PropsWithChildren<Props>> = ({
    fullScreenInitially,
    searchInFullScreen,
    fullScreenButton,
    backButton,
    containerClass,
    menuItems,
    selectFirstMenuItemWhenAvailable,
    search,
    titleBar: titleBarProps,
    view: viewProps,
    children,
    onMenuItemChanged,
}) => {
    const [previouslySelectedMenuItem, setPreviouslySelectedMenuItem] = useState<PanelMenuItem>();
    const [selectedMenuItem, setSelectedMenuItem] = useState(
        menuItems?.find(item => !item.isDisabled)
    );
    const [fullScreen, setFullScreen] = useState(fullScreenInitially ?? false);
    const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const changeSelectedMenuItem = useCallback(
        (menuItemToChangeTo?: PanelMenuItem) => {
            setPreviouslySelectedMenuItem(selectedMenuItem);
            setSelectedMenuItem(menuItemToChangeTo);
        },
        [selectedMenuItem]
    );

    const state: OverlayState = {
        previouslySelectedMenuItem,
        selectedMenuItem,
        fullScreen,
        backButton,
        isBackButtonVisible,
        showLoading,
        changeSelectedMenuItem,
        setFullScreen,
        setIsBackButtonVisible,
        setShowLoading,
    };

    useEffect(() => {
        if (!selectedMenuItem || !onMenuItemChanged) return;

        onMenuItemChanged(selectedMenuItem);
    }, [onMenuItemChanged, selectedMenuItem]);

    useEffect(() => {
        if (!selectFirstMenuItemWhenAvailable || !!selectedMenuItem || !menuItems) return;

        const itemToSelect = menuItems.find(item => !item.isDisabled);
        if (!itemToSelect) return;

        changeSelectedMenuItem(itemToSelect);
    }, [menuItems, selectFirstMenuItemWhenAvailable, selectedMenuItem, changeSelectedMenuItem]);

    const searchElement: JSX.Element | null =
        search && (!searchInFullScreen || fullScreen) ? (
            <div className="overlay-search">{search}</div>
        ) : null;

    const { selectedLinkLabel, view } = useOverlayView({
        searchElement,
        state,
        viewProps,
        menuItems,
    });
    const { titleBarElement, topPart } = useOverlayTitleBar({
        selectedLinkLabel,
        searchElement,
        state,
        titleBarProps,
    });

    const overlayContainerClasses = ["overlay-container"];
    overlayContainerClasses.push(titleBarProps?.aboveMenu ? "with-top-row" : "no-top-row");
    if (containerClass) overlayContainerClasses.push(containerClass);

    const fullScreenButtonElement =
        !showLoading && fullScreenButton ? (
            <button
                className="overlay-full-screen-button"
                onClick={() => setFullScreen(!fullScreen)}
            >
                {fullScreen ? <ShrinkFullScreenIcon /> : <ExpandFullScreenIcon />}
            </button>
        ) : null;

    return (
        <div className={overlayContainerClasses.join(" ")}>
            {/* If title bar needs to be placed above the menu, reserve a row for it */}
            {titleBarProps?.aboveMenu ? (
                <div className="overlay-top-title-bar">{titleBarElement}</div>
            ) : null}

            {/* One third of the screen, only shown when not in full screen */}
            {!fullScreen && (
                <div className="overlay-left">
                    {searchElement}

                    {viewProps?.title === "Vijesti" && <CategoryMessageDisplay />}

                    <PanelMenu
                        items={menuItems}
                        selectedItem={selectedMenuItem}
                        hidden={fullScreen}
                        onItemClicked={item => {
                            const isFullScreen = item.fullScreen ?? false;
                            setFullScreen(isFullScreen);
                            setIsBackButtonVisible(isFullScreen);
                            changeSelectedMenuItem(item);
                        }}
                    />
                </div>
            )}

            {/* Two thirds on the right or full screen */}
            <div className={`overlay-${fullScreen ? "full-screen" : "right"}`}>
                {topPart}

                {/* Injected view */}
                {view && (
                    <div className="overlay-view-container">
                        {showLoading && <Loading />}

                        {fullScreenButtonElement}

                        {view}
                    </div>
                )}

                {/* Optional - additional children */}
                {children}
            </div>
        </div>
    );
};
