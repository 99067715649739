import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  emailRegex,
  FormGroup,
  Input,
  InputLabel,
  isEmpty,
  useTitle
} from 'modules/common';
import { AppRoutes } from 'routing';
import {
  TermsOfUseModal,
  registerUser,
  formErrorMessages,
  AccountMessages,
  isValidEmail,
  useEpoljoprivredaData,
} from 'modules/auth';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { RootState } from 'store';
import { toast } from 'react-toastify';

const Fields = {
  category: 'category',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  passwordRepeat: 'passwordRepeat',
  consent: 'consent',
};

export const SignUp: React.FC = () => {
  useTitle('Registracija - eSavjetnik');
  const dispatch = useDispatch();
  const registration = useSelector((state: RootState) => state.registration);
  const { registrationError, registrationSuccess, errorMessage, isLoading } = registration;
  const { token, returnUrl } = useEpoljoprivredaData();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [email, setEmail] = useState('');
  const [showToUModal, setShowToUModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (registrationSuccess || !token) {
      history.push(AppRoutes.Home);
    }
  }, [history, registrationSuccess]);

  const toggleToUModal = () => {
    setShowToUModal(!showToUModal);
  };

  const clearFormError = (field: string) => {
    const filtered = Object.keys(formErrors)
      .filter((key) => key !== field)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: formErrors[key],
        };
      }, {});
    setFormErrors({ ...filtered });
  };

  const checkEmailError = (value: string) => {
    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        email: formErrorMessages.required,
      }));
      return;
    }

    if (!isValidEmail(value)) {
      setFormErrors((prev) => ({
        ...prev,
        email: formErrorMessages.invalidEmail,
      }));
      return;
    }

    clearFormError("email");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      toast.error('Email nije u dobrom formatu');
      return;
    }

    if (!token) {
      toast.error('Potrebno je pristupiti registraciji preko ePoljoprivreda sustava');
      return;
    }

    dispatch(registerUser({email, token, returnUrl}));
  };

  const buttonDisabled = email.length < 1 || !termsAccepted || Object.values(formErrors).length > 0;

  return (
    <div>
      <h1 className="signup-heading mt-48">Registracija</h1>
      {(registrationError || registrationSuccess) && (
        <AccountMessages
          error={registrationError}
          success={registrationSuccess}
          message={errorMessage}
        />
      )}
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputLabel
            labelFor={Fields.email}
            labelText="Email adresa"
            isValid={formErrors[Fields.email] ? false : true}
          />
          <Input
            name={Fields.email}
            id={Fields.email}
            isDisabled={isLoading}
            type="text"
            size="large"
            placeholder="Email adresa"
            isValid={formErrors[Fields.email] ? false : true}
            errorMessage={formErrors[Fields.email]}
            onChange={e => {
              setEmail(e.target.value);
              checkEmailError(e.target.value);
            }}
            onBlur={e => checkEmailError(e.target.value)}
          />
        </FormGroup>
        <div className="consent">
          <Checkbox
            type="checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(ta => !ta)}
            className="checkbox-label--normal"
            isDisabled={isLoading}
            htmlFor="consent"
            text={
              <div className="consent__text">
                Pristajem na{' '}
                <a onClick={toggleToUModal} className="consent__link">
                  Uvjete korištenja
                </a>{' '}
              </div>
            }
          />
        </div>
        {isLoading ? (
          <div className="ml-80">
            <Spinner className="spinner" />
          </div>
        ) : (
          <Button
            type="submit"
            variant="primary"
            size="large"
            isDisabled={buttonDisabled}
            className="mb-84"
          >
            <span>Registriraj se</span>
          </Button>
        )}
      </form>
      <TermsOfUseModal toggleModal={toggleToUModal} isVisible={showToUModal} />
    </div>
  );
};
