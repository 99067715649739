import React, { useRef } from "react";
import ReactDOM from "react-dom";

interface Props {
  isActive: boolean;
  modalHeader: React.ReactNode;
  modalFooter?: React.ReactNode;
  onClose: () => void;
  className?: string;
}
export const Modal: React.FC<Props> = ({
  children,
  isActive,
  modalHeader,
  modalFooter,
  className
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  /** Close modal on outside click */
  // const handleOutsideClick = () => onClose();
  // useOutsideClick(modalRef, handleOutsideClick);

  if (!isActive) {
    return null;
  }
  const existingRoot = document.querySelector("#modal");
  const parent = existingRoot ?? createRootElement("modal");

  return ReactDOM.createPortal(
    <>
      <div className="modal-overlay" />
      <div className="modal-content">
        <div ref={modalRef} className={`modal-container ${className ?? ""}`}>
          {modalHeader}
          {children}
          {modalFooter}
        </div>
      </div>
    </>,
    parent
  );
};

function createRootElement(id: string) {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
}
