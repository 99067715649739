import React from "react";
import { ReactComponent as SortIcon } from "assets/icons/sortIcon.svg";
import { HeaderGroup, SortingRule } from "react-table";

interface Props {
    headerGroups: HeaderGroup<object>[];
    sortBy?: SortingRule<object>;
}

function getSortIconClass(column: HeaderGroup<object>, sortBy: SortingRule<object> | undefined) {
    if (sortBy && sortBy.id === column.id) {
        return sortBy.desc ? "bottom-active" : "top-active";
    }
    return "";
}

export const TableHeader: React.FC<Props> = ({ headerGroups, sortBy }) => {
    return (
        <thead className="table-head">
            {headerGroups.map(headerGroup => (
                <tr className="table-srow" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) =>
                        column.id !== "info" ? (
                            <th
                                className={`administration-table-header ${column.className ?? ""}`}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                <span>{column.render("Header")}</span>
                                {column.disableSortBy ? null : (
                                    <span className="table-header-icon">
                                        <SortIcon className={getSortIconClass(column, sortBy)} />
                                    </span>
                                )}
                            </th>
                        ) : (
                            <th
                                className={`administration-table__info ${column.className ?? ""}`}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                {column.render("Header")}
                            </th>
                        )
                    )}
                </tr>
            ))}
        </thead>
    );
};
