import { useTitle } from "modules/common";
import { Overlay } from "modules/common/components/Overlay";
import { PanelMenuItem } from "modules/common/components/PanelMenu";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NewsRoutes } from "modules/common/constants/routes";
import { useRouteSearch } from "modules/common/hooks/useRouteSearch";

const menuItems: PanelMenuItem[] = [
    {
        label: "Vijesti",
        link: NewsRoutes.News,
    },
    {
        label: "Savjeti",
        link: NewsRoutes.Advice,
    },
];

export const News: React.FC = () => {
    useTitle("Vijesti - eSavjetnik");
    const { search } = useLocation();
    const [menuItem, setMenuItem] = useState<PanelMenuItem>();
    const { debouncedSearch, searchElement } = useRouteSearch({
        routes: [NewsRoutes.News, NewsRoutes.Advice],
        menuItem,
        inputProps: { placeholder: "Pretraži po naslovu, sadržaju i kategoriji." },
    });

    return (
        <Overlay
            backButton
            search={searchElement}
            view={{
                title: "Vijesti",
                source: menuItem => `${menuItem?.link ?? ""}${search}`,
                parameters: { search: debouncedSearch },
                dynamicHeight: true
            }}
            menuItems={menuItems}
            onMenuItemChanged={setMenuItem}
        />
    );
};
