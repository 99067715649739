import React from "react";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";

interface Props {
    text: string;
    isActive: boolean;
    showDropdown: boolean;
    className?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}
export const Filter: React.FC<Props> = ({
    text,
    children,
    className,
    isActive,
    onClear,
    onClick,
    showDropdown,
}) => {
    return (
        <div className="filter-container">
            <button
                onClick={onClick}
                className={`filter filter-base ${className ?? ""} ${
                    isActive ? "filter-active" : ""
                }`}
            >
                <span className="filter-content">
                    {text}
                    <ChevronDownIcon />
                    {isActive && <CancelIcon onClick={onClear} />}
                </span>
            </button>
            <div className={`filter-dropdown ${showDropdown ? "filter-dropdown-active" : ""}`}>
                {children}
            </div>
        </div>
    );
};
