import { logOff } from "modules/auth";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSession } from "services";
import { RootState } from "store";

export const CheckSession = () => {
    const dispatch = useDispatch();
    const signIn = useSelector((state: RootState) => state.signIn);

    const ping = useCallback(async () => {
        if (!signIn.signInSuccess || !signIn.user?.ePoljoprivredaGuid) return;
        const isSessionActive = await checkSession(signIn.user?.accessToken);
        if (!isSessionActive) {
            dispatch(logOff());
        }
    }, [signIn, dispatch]);

    useEffect(() => {
        window.onfocus = ping;

        ping();
    }, [ping]);

    return <></>;
};

export default CheckSession;
