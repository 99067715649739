import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DeactivateAccountModal, Settings } from "modules/dashboard";
import {
    Button,
    InfoNote,
    ProfileInfo,
    ProfileInfoCitizenForm,
    ProfileInfoForm,
    ProfileSkeleton,
    RoleType,
    useTitle,
} from "modules/common";
import { RootState } from "store";
import { sessionCache } from "modules/auth";

const isCitizen = (roleType: number) => RoleType.Citizen === roleType;
const isFarmer = (roleType: number) => RoleType.Farmer === roleType;

export const Profile: React.FC = () => {
    useTitle("Profil - eSavjetnik");
    const user = sessionCache().getSession();
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const { profile, isLoading, isUpdating } = useSelector((state: RootState) => state.profile);
    const roleType = user?.roleType.value ?? profile?.roleType?.value;

    const toggleDeactivateModal = () => {
        setShowDeactivateModal(!showDeactivateModal);
    };

    return (
        <>
            {isLoading || isUpdating ? (
                <ProfileSkeleton />
            ) : (
                <div className="profile">
                    <div className="profile-container">
                        <div className="profile-name"></div>
                        <div>
                            <span className="profile-title mb-28">Tvoje informacije</span>
                            <span className="profile-description">
                                Osnovne informacije o korisniku.
                            </span>
                            {isCitizen(roleType ?? RoleType.Citizen) ? (
                                <>
                                    <ProfileInfo className="mt-48">
                                        <span className="profile-settings-label">
                                            Ime i prezime
                                        </span>
                                        <span className="profile-settings-text">
                                            {profile?.firstName} {profile?.lastName}
                                        </span>
                                        <span className="profile-settings-label">Uloga</span>
                                        <span className="profile-settings-text">
                                            {profile?.roleType?.label ?? ""}
                                        </span>
                                        <span className="profile-settings-label">E-mail</span>
                                        <span className="profile-settings-text">
                                            {profile?.email}
                                        </span>
                                        {/* <span className="profile-settings-label">Država</span>
                    <span className="profile-settings-text">
                      {profile?.country}
                    </span> */}
                                        <span className="profile-settings-label">Mjesto</span>
                                        <span className="profile-settings-text">
                                            {profile?.settlement}
                                        </span>
                                        <span className="profile-settings-label">
                                            Poštanski broj
                                        </span>
                                        <span className="profile-settings-text">
                                            {profile?.postalNumber &&
                                                profile.postalNumber.length &&
                                                `${profile.postalNumber}`}
                                        </span>
                                        <span className="profile-settings-label">Adresa</span>
                                        <span className="profile-settings-text">
                                            {profile?.street} {profile?.streetNumber}
                                        </span>
                                        <span className="profile-settings-label">Županija</span>
                                        <span className="profile-settings-text">
                                            {profile?.county}
                                        </span>
                                    </ProfileInfo>
                                    <div className="section-separator" />
                                    <ProfileInfoCitizenForm />
                                </>
                            ) : (
                                <>
                                    <div className="mb-40">
                                        <InfoNote text="Prikazani podaci su povučeni iz drugog sustava i nije moguća promjene unutar e-Savjetnik sustava." />
                                    </div>
                                    <section>
                                        <ProfileInfo>
                                            <span className="profile-settings-label">
                                                Ime i prezime
                                            </span>
                                            <span className="profile-settings-text">
                                                {profile?.firstName} {profile?.lastName}
                                            </span>
                                            {isFarmer(roleType ?? RoleType.Farmer) ? (
                                                <>
                                                    <span className="profile-settings-label">
                                                        MIBPG
                                                    </span>
                                                    <span className="profile-settings-text">
                                                        {profile?.mibpg}
                                                    </span>
                                                </>
                                            ) : null}
                                            {profile?.legalEntityName ? (
                                                <>
                                                    <span className="profile-settings-label">
                                                        Poslovni subjekt
                                                    </span>
                                                    <span className="profile-settings-text">
                                                        {profile?.legalEntityName}
                                                    </span>
                                                </>
                                            ) : null}
                                            <span className="profile-settings-label">Uloga</span>
                                            <span className="profile-settings-text">
                                                {profile?.roleType?.label ?? ""}
                                            </span>
                                            <span className="profile-settings-label">E-mail</span>
                                            <span className="profile-settings-text">
                                                {profile?.email}
                                            </span>
                                            <span className="profile-settings-label">Mjesto</span>
                                            <span className="profile-settings-text">
                                                {profile?.settlement}
                                            </span>
                                            <span className="profile-settings-label">
                                                Poštanski broj
                                            </span>
                                            <span className="profile-settings-text">
                                                {profile?.postalNumber &&
                                                    profile.postalNumber.length &&
                                                    `${profile.postalNumber}`}
                                            </span>
                                            <span className="profile-settings-label">Adresa</span>
                                            <span className="profile-settings-text">
                                                {profile?.street} {profile?.streetNumber}
                                            </span>
                                            <span className="profile-settings-label">Županija</span>
                                            <span className="profile-settings-text">
                                                {profile?.county}
                                            </span>
                                        </ProfileInfo>
                                    </section>
                                    <div className="section-separator" />
                                    <ProfileInfoForm />
                                </>
                            )}

                            <div className="section-separator" />
                            <Settings
                                notificationTypes={profile?.notificationType}
                                infoTypes={profile?.infoType}
                            />
                            <div className="section-separator" />
                            <section>
                                <h2 className="deactivate-header">Deaktivacija računa</h2>
                                <span className="deactivate-description mb-36">
                                    Deaktivacijom računa gubite pravo pristupa e-Savjetnik sustavu.
                                </span>
                                <Button
                                    type="button"
                                    variant="warning-ghost"
                                    size="small"
                                    isDisabled={false}
                                    onClick={toggleDeactivateModal}
                                >
                                    <span>Deaktivacija računa</span>
                                </Button>
                            </section>
                        </div>
                    </div>
                </div>
            )}

            <DeactivateAccountModal
                toggleModal={toggleDeactivateModal}
                isVisible={showDeactivateModal}
            />
        </>
    );
};
