import { useTitle } from "modules/common";
import React, { Dispatch, memo, SetStateAction, useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import geoJson from "../assets/geoJson.json";
import { useGetDocumentContentQuery } from "services/documents";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";

const counties: { name: string; path: string | undefined }[] = [
    { name: "Istarska", path: "2. Istarska županija.pdf" },
    { name: "Karlovačka", path: "3. Karlovačka županija.pdf" },
    { name: "Vukovarsko-srijemska", path: "4. Vukovarsko-srijemska županija.pdf" },
    { name: "Zagrebačka", path: "5. Zagrebačka županija.pdf" },
    { name: "Grad Zagreb", path: "6. Grad Zagreb.pdf" },
    { name: "Krapinsko-zagorska", path: "7. Krapinsko-zagorska županija.pdf" },
    { name: "Varaždinska", path: "8. Varaždinska županija.pdf" },
    { name: "Koprivničko-križevačka", path: "9. Koprivničko-križevačka županija.pdf" },
    { name: "Bjelovarsko-bilogorska", path: "10. Bjelovarsko-bilogorska županija.pdf" },
    { name: "Međimurska", path: "11. Međimurska županija.pdf" },
    { name: "Virovitičko-podravska", path: "12. Virovitičko-podravska županija.pdf" },
    { name: "Sisačko-moslavačka", path: "13. Sisačko-moslavačka županija.pdf" },
    { name: "Požeško-slavonska", path: "14. Požeško-slavonska županija.pdf" },
    { name: "Brodsko-posavska", path: "15. Brodsko-posavska županija.pdf" },
    { name: "Osječko-baranjska", path: "16. Osječko-baranjska županija.pdf" },
    { name: "Šibensko-kninska", path: "" },
    { name: "Splitsko-dalmatinska", path: "" },
    { name: "Ličko-senjska", path: "" },
    { name: "Zadarska", path: "" },
    { name: "Primorsko-goranska", path: "" },
    { name: "Dubrovačko-neretvanska", path: "" },
];

interface Props {
    setShowLoading: Dispatch<SetStateAction<boolean>>;
}

export const FlavescenceDoree: React.FC<Props> = ({ setShowLoading }) => {
    useTitle("Zlatna žutica - eSavjetnik");
    const [tooltipCounty, setTooltipCounty] = useState("");
    const [tooltipInfo, setTooltipInfo] = useState("");
    const [selectedCounty, setSelectedCounty] = useState<string | null>(null);
    const [path, setPath] = useState<string | undefined>();
    const [content, setContent] = useState<string>();
    const documentQuery = useGetDocumentContentQuery(path ?? "", "karte-zlatne-zutice");

    const setTooltipContent = (county?: { name: string; path: string | undefined }) => {
        if (!county) return;
        setTooltipCounty(county.name);
        if (!county.path) setTooltipInfo("Nema dostupnih podataka");
    };

    const clearTooltipContent = () => {
        setTooltipCounty("");
        setTooltipInfo("");
    };

    useEffect(() => {
        if (!documentQuery.data) return;

        const blob = new Blob([documentQuery.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        setContent(url);
        clearTooltipContent();
    }, [documentQuery.data]);

    return (
        <div className="flavescence-doree-container">
            {content ? (
                <>
                    <button
                        className="round-button circle flavescence-doree-back-button"
                        onClick={() => setContent(undefined)}
                    >
                        <CancelIcon />
                    </button>

                    <iframe
                        src={content}
                        title={selectedCounty ?? ""}
                        onLoad={() => setShowLoading(false)}
                    />
                </>
            ) : (
                <>
                    <ComposableMap
                        data-tip=""
                        projection="geoMercator"
                        projectionConfig={{
                            rotate: [-17, -43.5, 0],
                            scale: 5000,
                        }}
                        zoomAndPan=""
                        className="map-chart"
                    >
                        <Geographies geography={geoJson}>
                            {({ geographies }) =>
                                geographies.map(geo => {
                                    const county = counties.find(
                                        x => x.name === geo.properties.NAME_1
                                    );

                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            stroke="white"
                                            onMouseEnter={() => setTooltipContent(county)}
                                            onMouseLeave={clearTooltipContent}
                                            style={{
                                                default: {
                                                    outline: "none",
                                                    opacity: county?.path ? 1 : 0.5,
                                                },
                                                hover: {
                                                    outline: "none",
                                                    cursor: county?.path ? "pointer" : "default",
                                                },
                                                pressed: { outline: "none" },
                                            }}
                                            onClick={() => {
                                                setSelectedCounty(county?.name ?? null);
                                                setPath(county?.path ?? "");
                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    </ComposableMap>
                    <ReactTooltip type="light">
                        {tooltipCounty && (
                            <>
                                <b>{tooltipCounty}</b>
                                <br />
                                {tooltipInfo}
                            </>
                        )}
                    </ReactTooltip>
                </>
            )}
        </div>
    );
};

export default memo(FlavescenceDoree);
