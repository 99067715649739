import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "@tanstack/react-query";
import { useAccessToken, useStandardErrorHandler } from "modules/common";
import { toast } from "react-toastify";
import {
    getOuterModules,
    getPublicOuterModules,
    createOuterModule,
    GetOuterModuleRequest,
    OuterModuleResponse,
    CreateOuterModuleRequest,
    OuterModule,
    editOuterModule,
    EditOuterModuleRequest,
    deleteOuterModule,
} from "services";

export const useOuterModules = (
    data: GetOuterModuleRequest,
    options?: UseQueryOptions<OuterModuleResponse>
) => {
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju vanjskih modula");
    const accessToken = useAccessToken();
    const { enabled, ...opts } = options ?? {};

    const queryFn = accessToken
        ? () => getOuterModules(data, accessToken)
        : () => getPublicOuterModules(data);

    return useQuery({
        queryKey: ["outerModules", data],
        queryFn,
        onError: errorHandler,
        keepPreviousData: true,
        ...opts,
    });
};

export const useCreateOuterModule = (
    options?: UseMutationOptions<OuterModule, unknown, CreateOuterModuleRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri stvaranju vanjskog modula");

    return useMutation({
        mutationFn: (data: CreateOuterModuleRequest) => createOuterModule(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["outerModules"]);
            queryClient.invalidateQueries(["outerModuleVersions"]);
            toast.success("Vanjski modul je uspješno stvoren");
        },
        ...options,
    });
};

export const useEditOuterModule = (
    options?: UseMutationOptions<OuterModule, unknown, EditOuterModuleRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri uređivanju vanjskog modula");

    return useMutation({
        mutationFn: (data: EditOuterModuleRequest) => editOuterModule(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["outerModules"]);
            queryClient.invalidateQueries(["outerModuleVersions"]);
            toast.success("Vanjski modul je uspješno uređen");
        },
        ...options,
    });
};

export const useDeleteOuterModule = (
    options?: UseMutationOptions<OuterModule, unknown, string>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri brisanju vanjskog modula");

    return useMutation({
        mutationFn: (id: string) => deleteOuterModule(id, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["outerModules"]);
            queryClient.invalidateQueries(["outerModuleVersions"]);
            toast.success("Vanjski modul je uspješno obrisan");
        },
        ...options,
    });
};
