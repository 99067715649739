import { useCallback, useEffect } from "react";

interface IframeMessage<T extends string, P = any> {
    type: T;
    payload: P;
    source: string;
    timestamp?: number;
    id?: string;
}

export const useIframeMessage = <P>(type: string, handler: (payload: P) => void) => {
    const messageHandler = useCallback(
        (event: MessageEvent<IframeMessage<string, P>>) => {
            if (event.data?.source !== "esavjetnik-iframe") return;

            if (event.data.type === type) {
                handler(event.data.payload);
            }
        },
        [type, handler]
    );

    useEffect(() => {
        window.addEventListener("message", messageHandler);
        return () => {
            window.removeEventListener("message", messageHandler);
        };
    }, [messageHandler]);
};
