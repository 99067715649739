import { AbilityBuilder, Ability, AbilityClass } from "@casl/ability";
import {
  isCitizen,
  isMpSuperAdmin,
  isMpAdminFarmer,
  // isMpAdminFisherman,
  isEmployeeMp,
  isEmployeeApprrr,
  isAdviserSuperAdmin,
  isAdviserAdminFarmer,
  // isAdviserAdminFisherman,
  isFarmer,
  // isFisherman,
  isEmployeeAdviser,
} from "./roleChecks";
import { Subjects } from "./subjects";
import { Actions } from "./actions";
import {
  defineRulesForAdviser,
  defineRulesForAdviserAdminFarmer,
  // defineRulesForAdviserAdminFisherman,
  defineRulesForAdviserSuperAdmin,
  defineRulesForCitizen,
  defineRulesForEmployeeApprrr,
  defineRulesForEmployeeMp,
  defineRulesForFarmer,
  // defineRulesForFisherman,
  defineRulesForMpAdminFarmer,
  // defineRulesForMpAdminFisherman,
  defineRulesForMpSuperAdmin,
} from "./roleRules";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbilityModel = Ability as AbilityClass<AppAbility>;
export const ability = new AppAbilityModel([], {});

export default function defineRoleRules(role: number) {
  const abilityBuilder = new AbilityBuilder(AppAbilityModel);

  if (isMpSuperAdmin(role)) {
    return defineRulesForMpSuperAdmin(abilityBuilder);
  }

  if (isMpAdminFarmer(role)) {
    return defineRulesForMpAdminFarmer(abilityBuilder);
  }

  // if (isMpAdminFisherman(role)) {
  //   return defineRulesForMpAdminFisherman(abilityBuilder);
  // }

  if (isEmployeeMp(role)) {
    return defineRulesForEmployeeMp(abilityBuilder);
  }

  if (isEmployeeApprrr(role)) {
    return defineRulesForEmployeeApprrr(abilityBuilder);
  }

  if (isEmployeeAdviser(role)) {
    return defineRulesForAdviser(abilityBuilder);
  }

  if (isAdviserSuperAdmin(role)) {
    return defineRulesForAdviserSuperAdmin(abilityBuilder);
  }

  if (isAdviserAdminFarmer(role)) {
    return defineRulesForAdviserAdminFarmer(abilityBuilder);
  }

  // if (isAdviserAdminFisherman(role)) {
  //   return defineRulesForAdviserAdminFisherman(abilityBuilder);
  // }

  if (isFarmer(role)) {
    return defineRulesForFarmer(abilityBuilder);
  }

  // if (isFisherman(role)) {
  //   return defineRulesForFisherman(abilityBuilder);
  // }

  if (isCitizen(role)) {
    return defineRulesForCitizen(abilityBuilder);
  }

  return defineRulesForFarmer(abilityBuilder);
}

export function buildAbilityFor(role: number) {
  ability.update(defineRoleRules(role));
}

export function clearAbility() {
  ability.update([]);
}