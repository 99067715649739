import { getDisplayDateFormat } from "helpers/DatesHelper";
import { useTitle } from "modules/common";
import { InfoBox } from "modules/common/components/InfoBox";
import { Overlay } from "modules/common/components/Overlay";
import { PanelMenuItem } from "modules/common/components/PanelMenu";
import SearchableDropdown from "modules/common/components/SearchableDropdown/SearchableDropdown";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import React, { useMemo, useState } from "react";
import { useForecastCities } from "services/iot/hooks";
import { ForecastCityDto } from "services/iot/models";
import { AgrometeorologyRoutes } from "modules/common/constants/routes";

const menuItems: PanelMenuItem[] = [
    {
        label: "Danas",
        link: AgrometeorologyRoutes.WeatherToday,
    },
    {
        label: "Prognoza",
        link: AgrometeorologyRoutes.CityWeatherForecast,
        fullScreen: true,
    },
    {
        label: "Agrometeorološka prognoza",
        link: AgrometeorologyRoutes.AgrometeorologyForecast,
    },
    {
        label: "Karta kretanja oblaka",
        link: AgrometeorologyRoutes.CloudMovement,
        fullScreen: true,
    },
    {
        label: "Karta vjetra i temperature",
        link: AgrometeorologyRoutes.WindAndTemperatureMap,
    },
];

export const Agrometeorology: React.FC = () => {
    useTitle("Agrometeorologija - eSavjetnik");

    const [city, setCity] = useState<ForecastCityDto>();
    const [menuItem, setMenuItem] = useState<PanelMenuItem>();

    const { data: cities } = useForecastCities();

    const titleBarProps =
        menuItem?.link === AgrometeorologyRoutes.AgrometeorologyForecast
            ? {
                  aboveMenu: true,
                  text: "IZVOR PODATAKA: DHMZ",
                  fullScreenButton: false,
              }
            : undefined;

    const search = useMemo(() => {
        if (menuItem) {
            const { link } = menuItem;
            // WeatherToday && CityWeatherForecast need to show the search box
            if (
                link !== AgrometeorologyRoutes.WeatherToday &&
                link !== AgrometeorologyRoutes.CityWeatherForecast
            ) {
                // WindAndTemperatureMap shows some info instead of search, for others, use null
                if (link !== AgrometeorologyRoutes.WindAndTemperatureMap) return null;

                return (
                    <InfoBox
                        left={getDisplayDateFormat(new Date())}
                        right={"Izvor podataka: DHMZ"}
                    />
                );
            }
        }

        return (
            <SearchableDropdown
                items={cities}
                noOptionsMessage={() => "Nema pronađenih gradova"}
                labelKey={"localizedName"}
                onSelect={setCity}
                placeholder="Pretraživanje"
                customIcons={{
                    searchIcon: <SearchIcon width={14} height={14} />,
                }}
            />
        );
    }, [cities, menuItem]);

    return (
        <Overlay
            backButton
            search={search}
            view={{
                title: "Agrometeorologija",
                source: "agrometeorologija",
                parameters: { tab: menuItem?.link, city: city?.name },
                dynamicHeight: true,
            }}
            menuItems={menuItems}
            onMenuItemChanged={setMenuItem}
            titleBar={titleBarProps}
        />
    );
};
