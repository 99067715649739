import React from "react";
import { Filter, RadioInput } from "..";
import { userAccountActiveStatus } from "modules/common/constants/userAccountActiveStatus";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isActive: boolean;
    showDropdown: boolean;
    value: number;
    title?: string;
}

export const StatusFilter: React.FC<Props> = ({
    onChange,
    onClick,
    onClear,
    isActive,
    showDropdown,
    value,
    title,
}) => {
    return (
        <Filter
            isActive={isActive}
            showDropdown={showDropdown}
            onClear={onClear}
            onClick={onClick}
            text="STATUS"
        >
            <RadioInput
                value={userAccountActiveStatus.Active.value.toString()}
                checked={userAccountActiveStatus.Active.value === value}
                onChange={onChange}
                size="small"
                htmlFor={userAccountActiveStatus.Active.label}
                text={
                    <div className="filter__item__text">
                        {userAccountActiveStatus.Active.label.toLocaleUpperCase()}
                    </div>
                }
            />
            <RadioInput
                value={userAccountActiveStatus.Inactive.value.toString()}
                checked={userAccountActiveStatus.Inactive.value === value}
                onChange={onChange}
                size="small"
                htmlFor={userAccountActiveStatus.Inactive.label}
                text={
                    <div className="filter__item__text">
                        {userAccountActiveStatus.Inactive.label.toLocaleUpperCase()}
                    </div>
                }
            />
        </Filter>
    );
};
