interface JwtBody {
  acr: string;
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  realm_access: {
    roles: string[];
  };
  resource_access: {
    "KingICT.eAdviser": {
      roles: string[];
    };
    account: {
      roles: string[];
    };
  };
  scope: string;
  session_state: string;
  sub: string;
  typ: string;
  NavToken?: string;
  MessageId?: string;
  ForPersonOib?: string;
  ForLegalIps?: string;
  ToLegalIps?: string;
  ToLegalIzvorReg?: string;
  ForLegalIzvorReg?: string;
  oib?: string;
  EPoljoprivredaGuid?: string;
  previous_login: string;
}

export const decodeJwt = (jwt: string) => {
  const parts = jwt.split(".");
  const [, second] = parts;

  const body: JwtBody = JSON.parse(atob(second));
  return body;
};
