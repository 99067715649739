import { Can } from "modules/auth";
import React from "react";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "routing";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { sessionCache } from "modules/auth";
import { RoleType } from "modules/common";

const isFarmer = (roleType: number) => RoleType.Farmer === roleType;

export const EducationTabNavigation: React.FC = () => {
  const user = sessionCache().getSession();
  const { profile } = useSelector((state: RootState) => state.profile);
  const roleType = user?.roleType.value ?? profile?.roleType?.value;

  return (
    <div className="separator">
      <Can I="view" a="EducationModule">
        <NavLink
          activeClassName="educations__filter-active"
          className="educations__filter mr-12"
          to={AppRoutes.Education}
        >
          {isFarmer(roleType ?? RoleType.Farmer)
            ? "Edukacije"
            : "Analiza edukacija"}
        </NavLink>
      </Can>
      <Can I="view" a="PresentationsModule">
        <NavLink
          activeClassName="educations__filter-active"
          className="educations__filter"
          to={AppRoutes.Presentations}
        >
          Prezentacije
        </NavLink>
      </Can>
    </div>
  );
};
