import { useIframeMessage } from "modules/common/hooks/useIframeMessage";
import { useState } from "react";

type NewsCategoryTextChangedMessage = {
    categoryText: string;
    isArticleSelected: boolean;
};

export const CategoryMessageDisplay = () => {
    const [categoryText, setCategoryText] = useState("");
    const [isArticleSelected, setIsArticleSelected] = useState(false);

    useIframeMessage<NewsCategoryTextChangedMessage>("NEWS_CATEGORY_TEXT_UPDATE", payload => {
        setCategoryText(payload.categoryText);
        setIsArticleSelected(payload.isArticleSelected);
    });

    useIframeMessage<void>("NEWS_CATEGORY_TEXT_CLEAR", () => {
        setCategoryText("");
    });

    if (!categoryText) return null;

    if (isArticleSelected) return null;

    return (
        <div className="category-filter-display">
            <span>Pretraživanje obuhvaća naslov i kategorije definirane uz: {categoryText}</span>
        </div>
    );
};
