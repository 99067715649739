import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, InputLabel, isEmpty, useTitle } from 'modules/common';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as ValidIcon } from 'assets/icons/valid.svg';
import { ReactComponent as BaseValidIcon } from 'assets/icons/base-valid.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import {
  calculatePasswordStrength,
  formErrorMessages,
  getUrlCode,
  isEightCharLong,
  isOneLowerCaseChar,
  isOneNumericChar,
  isOneUpperCaseChar,
  LinkExpired,
  PasswordStrength,
  PassworRequirements,
  verfiyPasswordLink,
  verifyPasswordResetLinkError,
  updatePassword,
} from 'modules/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppRoutes } from 'routing';

interface FormState {
  password: string;
  passwordRepeat: string;
}

const Fields = {
  password: 'password',
  passwordRepeat: 'passwordRepeat',
};

export const ChangePassword: React.FC = () => {
  useTitle('Nova lozinka - eSavjetnik');
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const changePassword = useSelector(
    (state: RootState) => state.changePassword
  );
  const {
    verifySuccess,
    verifyError,
    changePasswordSuccess,
    code: changePasswordCode,
    isLoading,
  } = changePassword;
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formState, setFormState] = useState<FormState>({
    password: '',
    passwordRepeat: '',
  });
  const [showPwdReq, setShowPwdReq] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [lengthFulfilled, setLengthFulfilled] = useState(false);
  const [numberFulfilled, setNumberFulfilled] = useState(false);
  const [charsFulfilled, setCharsFulfilled] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);

  useEffect(() => {
    if (changePasswordSuccess) {
      history.push({
        pathname: AppRoutes.SignIn,
      });
    }
  }, [changePasswordSuccess, history]);

  useEffect(() => {
    const code = getUrlCode(search);

    if (!code) {
      dispatch(verifyPasswordResetLinkError());
      return;
    }

    dispatch(verfiyPasswordLink(code));
  }, [search]);

  useEffect(() => {
    const code = getUrlCode(search);
    if (!code) {
      setIsPasswordResetSuccess(false);
      return;
    }

    if (code && verifyError) {
      setIsPasswordResetSuccess(false);
      return;
    }

    if (code && verifySuccess) {
      setIsPasswordResetSuccess(true);
      return;
    }
  }, [verifyError, verifySuccess, search]);

  const passwordsMatch = () => {
    return formState.password === formState.passwordRepeat;
  };

  const clearFormError = (field: string) => {
    const filtered = Object.keys(formErrors)
      .filter((key) => key !== field)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: formErrors[key],
        };
      }, {});
    setFormErrors({ ...filtered });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    checkPassword(value);

    setFormState((prev) => ({
      ...prev,
      [event.target.name]: value,
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setShowPwdReq(false);

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      setPasswordScore(0);
      setLengthFulfilled(false);
      setCharsFulfilled(false);
      setNumberFulfilled(false);
      return;
    }

    if (
      !isOneLowerCaseChar(value) ||
      !isOneUpperCaseChar(value) ||
      !isOneNumericChar(value) ||
      !isEightCharLong(value)
    ) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidPassword,
      }));
      return;
    }

    if (formState.passwordRepeat.length && !passwordsMatch()) {
      setDoPasswordsMatch(false);
      setFormErrors((prev) => ({
        ...prev,
        [Fields.passwordRepeat]: formErrorMessages.invalidPassword,
      }));

      return;
    }

    clearFormError(name);
  };

  const checkPassword = (value: string) => {
    if (isOneLowerCaseChar(value) && isOneLowerCaseChar(value)) {
      setCharsFulfilled(true);
    } else {
      setCharsFulfilled(false);
    }

    if (isOneNumericChar(value)) {
      setNumberFulfilled(true);
    } else {
      setNumberFulfilled(false);
    }

    if (isEightCharLong(value)) {
      setLengthFulfilled(true);
    } else {
      setLengthFulfilled(false);
    }

    setPasswordScore(calculatePasswordStrength(value));
  };

  const handlePasswordRepeatBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      return;
    }

    clearFormError(name);

    if (!passwordsMatch()) {
      setDoPasswordsMatch(false);
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidPassword,
      }));

      return;
    }

    setDoPasswordsMatch(true);
    clearFormError(name);
  };

  const handleShowPassword = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => setShowPassword((prev) => !prev);

  const handleShowPassworRepeat = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => setShowPasswordRepeat((prev) => !prev);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const code = getUrlCode(search);

    if (!code) return;
    dispatch(updatePassword(code, formState.password));
  };

  const buttonDisabled =
    Object.values(formState).some((x) => x.length < 1) ||
    Object.values(formErrors).length > 0;
  return (
    <div>
      {isPasswordResetSuccess ? (
        <>
          <h1 className="password-heading mt-72">Nova lozinka</h1>
          <form onSubmit={handleSubmit}>
            <div className="password">
              <FormGroup>
                <InputLabel
                  labelFor={Fields.password}
                  labelText="Lozinka"
                  isValid={true}
                />
                <Input
                  name={Fields.password}
                  id={Fields.password}
                  type={showPassword ? 'text' : 'password'}
                  size="large"
                  placeholder="Lozinka"
                  isValid={formErrors[Fields.password] ? false : true}
                  errorMessage={formErrors[Fields.password]}
                  rightIcon={
                    showPassword ? (
                      <EyeOffIcon onClick={handleShowPassword} />
                    ) : (
                      <EyeIcon onClick={handleShowPassword} />
                    )
                  }
                  onChange={handlePasswordChange}
                  onFocus={() => {
                    setShowPwdReq(true);
                  }}
                  onBlur={handlePasswordBlur}
                >
                  <div className="input-password-strength">
                    <PasswordStrength score={passwordScore} />
                  </div>
                  <PassworRequirements
                    className="pwd-requirements-container"
                    isActive={showPwdReq}
                    lengthRequirement={lengthFulfilled}
                    charRequiremenet={charsFulfilled}
                    numberRequirement={numberFulfilled}
                  />
                </Input>
              </FormGroup>
            </div>
            <div className="repeat-password">
              <InputLabel
                labelFor={Fields.passwordRepeat}
                labelText="Lozinka"
                isValid={true}
              />
              <Input
                name={Fields.passwordRepeat}
                id={Fields.passwordRepeat}
                type={showPasswordRepeat ? 'text' : 'password'}
                size="large"
                placeholder="Lozinka"
                isValid={formErrors[Fields.passwordRepeat] ? false : true}
                errorMessage={formErrors[Fields.passwordRepeat]}
                leftIcon={doPasswordsMatch ? <ValidIcon /> : <BaseValidIcon />}
                rightIcon={
                  showPasswordRepeat ? (
                    <EyeOffIcon onClick={handleShowPassworRepeat} />
                  ) : (
                    <EyeIcon onClick={handleShowPassworRepeat} />
                  )
                }
                onChange={handleInputChange}
                onBlur={handlePasswordRepeatBlur}
              />
            </div>
            <div className="signin-box">
              {isLoading ? (
                <div className="ml-80">
                  <Spinner className="spinner" />
                </div>
              ) : (
                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  isDisabled={buttonDisabled}
                >
                  <span>Snimi novu lozinku</span>
                </Button>
              )}
            </div>
          </form>
        </>
      ) : (
        <LinkExpired />
      )}
    </div>
  );
};
