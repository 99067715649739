import { getAuthHeaders, getFormData } from "modules/common";
import { createService } from "services/serviceFactory";
import {
    CreateOuterModuleRequest,
    GetOuterModuleRequest,
    EditOuterModuleRequest,
    GetOuterModuleVersionRequest,
} from "./request";
import {
    OuterModule,
    OuterModuleResponse,
    OuterModuleVersion,
    OuterModuleVersionDetails,
    OuterModuleVersionResponse,
} from "./response";

const outerModulesService = createService("outermodules");

async function getPublicOuterModules(data: GetOuterModuleRequest) {
    const response = await outerModulesService.get<OuterModuleResponse>(
        "/outermodules/v1/outermodules/public",
        { params: data }
    );
    return response.data;
}

async function getOuterModules(data: GetOuterModuleRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await outerModulesService.get<OuterModuleResponse>(
        "/outermodules/v1/outermodules",
        { params: data, ...authHeaders }
    );
    return response.data;
}

async function createOuterModule(data: CreateOuterModuleRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await outerModulesService.post<OuterModule>(
        "/outermodules/v1/outermodules",
        formData,
        authHeaders
    );
    return response.data;
}

async function editOuterModule(data: EditOuterModuleRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const formData = getFormData(data);
    const response = await outerModulesService.patch<OuterModule>(
        `/outermodules/v1/outermodules`,
        formData,
        authHeaders
    );
    return response.data;
}

async function deleteOuterModule(id: string, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await outerModulesService.delete<OuterModule>(
        `/outermodules/v1/outermodules/${id}`,
        authHeaders
    );
    return response.data;
}

async function getOuterModuleVersions(data: GetOuterModuleVersionRequest, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await outerModulesService.get<OuterModuleVersionResponse>(
        "/outermodules/v1/outermodules/versions",
        { params: data, ...authHeaders }
    );
    return response.data;
}

async function getOuterModuleVersionDetails(id: string, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await outerModulesService.get<OuterModuleVersionDetails>(
        `/outermodules/v1/outermodules/versions/${id}`,
        authHeaders
    );
    return response.data;
}

async function restoreOuterModuleVersion(id: string, accessToken: string) {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await outerModulesService.patch<OuterModuleVersion>(
        `/outermodules/v1/outermodules/versions/${id}`,
        null,
        authHeaders
    );
    return response.data;
}

export {
    getOuterModules,
    getPublicOuterModules,
    createOuterModule,
    editOuterModule,
    deleteOuterModule,
    getOuterModuleVersions,
    getOuterModuleVersionDetails,
    restoreOuterModuleVersion
};
