import { useState, useMemo } from "react";
import { PanelMenuItem } from "../components/PanelMenu";
import useDebounced from "./useDebounced";
import { Input, PropsInput } from "../components";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

export interface RouteSearchProps {
    routes: string[];
    menuItem?: PanelMenuItem;
    inputFactory?: (
        searchValue: string,
        changeSearchValue: (newValue: string) => void
    ) => JSX.Element;
    inputProps?: Omit<Partial<PropsInput>, "value" | "onChange">;
    debounceMilliseconds?: number;
}

export const useRouteSearch = ({
    routes,
    menuItem,
    inputFactory,
    inputProps,
    debounceMilliseconds,
}: RouteSearchProps) => {
    const [searchText, setSearchText] = useState(
        routes.reduce((searchPerRoute, route) => {
            searchPerRoute[route] = "";
            return searchPerRoute;
        }, {} as Record<string, string>)
    );
    const currentItemSearch = useMemo(
        () => menuItem?.link && searchText[menuItem.link],
        [menuItem?.link, searchText]
    );
    const debouncedSearch = useDebounced(currentItemSearch, debounceMilliseconds ?? 500);

    const searchElement = useMemo(() => {
        if (!menuItem || !routes.length) return null;

        const { link } = menuItem;
        if (!routes.includes(link)) return null;

        const searchValue = searchText[link];
        const changeSearchValue: (newValue: string) => void = newValue =>
            setSearchText(previous => ({
                ...previous,
                [link]: newValue,
            }));

        return inputFactory ? (
            inputFactory(searchValue, changeSearchValue)
        ) : (
            <Input
                id="search"
                name="search"
                errorMessage=""
                type="text"
                isValid
                size="medium"
                rightIcon={<SearchIcon />}
                {...inputProps}
                value={searchValue}
                onChange={e => changeSearchValue(e.target.value)}
            />
        );
    }, [inputFactory, inputProps, menuItem, routes, searchText]);

    return { debouncedSearch, searchElement };
};
