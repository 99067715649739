import { ReactNode } from "react";

interface Props {
    left?: ReactNode;
    right?: ReactNode;
}

export const InfoBox: React.FC<Props> = ({ left, right }) => {
    return (
        <div className="info-box">
            <div className="info-box-left">{left}</div>
            <span className="info-box-right">{right}</span>
        </div>
    );
};
