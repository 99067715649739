import {
  actionTypes,
  CountyFilterFields,
  infoTypes,
  notificationTypes,
  userRoles,
} from "modules/common";

export const mapUserRoles = (role: number) =>
  Object.values(userRoles).find((obj) => role === obj.value);

export const mapSingleRole = (role: number) =>
  Object.values(userRoles).find((obj) => obj.value === role);

export const mapCounty = (county: number) =>
  Object.values(CountyFilterFields).find((o) => o.value === county);

export const mapUserRole = (userRole: number) =>
  Object.values(userRoles).find((o) => o.value === userRole);

export const mapNotifications = (notificationType: number[]) =>
  Object.values(notificationTypes)
    .filter((o) => notificationType.includes(o.value))
    .map((s) => s.label);

export const mapSubscriptions = (subscriptions: number[]) =>
  Object.values(infoTypes)
    .filter((o) => subscriptions.includes(o.value))
    .map((s) => s.label);

export const mapApplicationAction = (action: number) =>
  Object.values(actionTypes).find((obj) => obj.value === action);

export const mapApplicationType = (application: number) =>
  Object.values(mapApplicationType).find((obj) => obj.value === application);
