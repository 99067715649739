import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { Button, Loading } from "modules/common";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";
import { toast } from "react-toastify";

interface Props {
    onUpload: (file: File | null) => void;
    acceptedFiles: string;
    isActive: boolean;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    title?: string;
    subtitle?: string;
    maxFileSize?: number;
    isLoading?: boolean;
    isEditPresentation?: boolean;
    isDisabled?: boolean;
}
export const FileUpload: React.FC<Props> = ({
    onUpload,
    acceptedFiles,
    title,
    subtitle,
    maxFileSize,
    isLoading,
    isActive,
    onClear,
    isEditPresentation,
    isDisabled = false,
}) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(isLoading);
    const [fileName, setFileName] = useState<string>();
    const maxSize = maxFileSize || 150;
    function preventSubmit(e: { preventDefault: () => void }) {
        e.preventDefault();
    }

    function handleClick() {
        hiddenFileInput.current?.click();
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        if (!files) {
            return;
        }

        const file = files[0];
        // File size is in bytes
        const fileSizeInMb = file?.size / 1048576;

        if (fileSizeInMb > maxSize) {
            toast.error("Datoteka je prevelika");
            return;
        }

        setFileName(file?.name);
        onUpload(file);
    }

    function handleClear(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = "";
        }
        onClear(event);
    }

    useEffect(() => {
        if (!subtitle) {
            setLoading(isLoading);
            return;
        }
        setLoading(!Boolean(subtitle));
    }, [subtitle]);

    return (
        <div className="mt-24 mb-40">
            {loading ? (
                <div className="f f-align-items-center">
                    <Loading className="presentation__modal-loading" />
                    <p>Datoteka se učitava</p>
                </div>
            ) : (
                <>
                    <Button
                        type="button"
                        onClick={handleClick}
                        className="btn btn--upload"
                        isDisabled={isDisabled}
                        size="medium"
                    >
                        <AddIcon className="mr-8" />
                        {title || `Dodaj prilog (max ${maxSize}mb)`}
                    </Button>
                    <input
                        ref={hiddenFileInput}
                        type="file"
                        accept={acceptedFiles}
                        onChange={handleChange}
                        hidden
                    />
                    <p className="btn--upload--title">
                        {subtitle || `Tipovi dokumenata ${acceptedFiles}`}
                    </p>

                    {isActive && (
                        <button
                            onClick={e => preventSubmit(e)}
                            className={`filter filter-base ${isActive ? "filter-active" : ""}`}
                        >
                            <span className="filter-content">
                                {fileName || subtitle}
                                {isActive && !isEditPresentation && (
                                    <CancelIcon onClick={handleClear} />
                                )}
                            </span>
                        </button>
                    )}
                </>
            )}
        </div>
    );
};
