import React, { useEffect, useRef, useState } from "react";
import { FilterGroup, ActionFilter, useOutsideClick, RoleFilter } from "modules/common";
import {
    useGetFilterPreferencesQuery,
    useUpdateFilterPreferences,
} from "modules/common/hooks/filterPreferencesHooks";

interface Props {
    actionFilter: number[];
    roleFilter: number[];
    setActionFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setRoleFilter: React.Dispatch<React.SetStateAction<number[]>>;
}
export const ActivityFilters: React.FC<Props> = ({
    actionFilter,
    roleFilter,
    setActionFilter,
    setRoleFilter,
}) => {
    const filterPreferencesQuery = useGetFilterPreferencesQuery([
        `${ActivityFilters.name}-roleFilter`,
        `${ActivityFilters.name}-actionFilter`,
    ]);
    const updateFilterPreferencesMutation = useUpdateFilterPreferences();

    const [showActionFilter, setShowActionFilter] = useState(false);
    const [showRoleFilter, setShowRoleFilter] = useState(false);
    const actionFilterRef = useRef<HTMLDivElement>(null);
    const roleFilterRef = useRef<HTMLDivElement>(null);

    const isRoleFilterActive = roleFilter.length > 0;
    const isActionFilterActive = actionFilter.length > 0;

    const handleActionFilterOutsideClick = () => {
        setShowActionFilter(false);
    };

    const handleRoleFilterOutsideClick = () => {
        setShowRoleFilter(false);
    };

    useOutsideClick(actionFilterRef, handleActionFilterOutsideClick);
    useOutsideClick(roleFilterRef, handleRoleFilterOutsideClick);

    const handleActionFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setActionFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
        handleUpdateFilterPreferences(`${ActivityFilters.name}-actionFilter`, valueNumb);
    };

    const handleRoleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const valueNumb = parseInt(value);
        setRoleFilter(prev => {
            if (!prev.includes(valueNumb)) {
                return [...prev, valueNumb];
            } else {
                return [...prev.filter(s => s !== valueNumb)];
            }
        });
        handleUpdateFilterPreferences(`${ActivityFilters.name}-roleFilter`, valueNumb);
    };

    const handleActionFilterClear = (_event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        handleUpdateFilterPreferences(`${ActivityFilters.name}-actionFilter`, -1);
        setActionFilter([]);
    };

    const handleRoleFilterClear = (_event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        handleUpdateFilterPreferences(`${ActivityFilters.name}-roleFilter`, -1);
        setRoleFilter([]);
    };

    const handleActionFilterClick = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowActionFilter(prev => !prev);
    };

    const handleRoleFilterClick = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowRoleFilter(prev => !prev);
    };

    const handleUpdateFilterPreferences = (filterName: string, filterValue: number) => {
        updateFilterPreferencesMutation.mutate({
            filterName,
            filterValue,
        });
    };

    useEffect(() => {
        if (filterPreferencesQuery.some(s => s.isLoading)) {
            return;
        }

        const roleFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${ActivityFilters.name}-roleFilter`
        )?.data?.filterValues;

        const actionFilterData = filterPreferencesQuery.find(
            fp => fp.data?.filterName === `${ActivityFilters.name}-actionFilter`
        )?.data?.filterValues;

        if (roleFilterData) {
            setRoleFilter(roleFilterData);
        }

        if (actionFilterData) {
            setActionFilter(actionFilterData);
        }
    }, [filterPreferencesQuery]);

    return (
        <>
            <FilterGroup text="">
                <div className="mr-4 " ref={roleFilterRef}>
                    <RoleFilter
                        onChange={handleRoleFilterChange}
                        onClear={handleRoleFilterClear}
                        onClick={handleRoleFilterClick}
                        value={roleFilter}
                        isActive={isRoleFilterActive}
                        showDropdown={showRoleFilter}
                    />
                </div>
                <div ref={actionFilterRef}>
                    <ActionFilter
                        onChange={handleActionFilterChange}
                        onClear={handleActionFilterClear}
                        onClick={handleActionFilterClick}
                        value={actionFilter}
                        isActive={isActionFilterActive}
                        showDropdown={showActionFilter}
                    />
                </div>
            </FilterGroup>
        </>
    );
};
