import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  userId: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  role: { value: number; label: string };
  navToken?: string;
  messageId?: string;
  oib?: string;
  forPersonOib?: string;
  forLegalIps?: string;
  toLegalIps?: string;
  toLegalIzvorReg?: string;
  forLegalIzvorReg?: string;
  ePoljoprivredaGuid?: string;
  showChangeEntityDialog?: boolean;
  previousLogin?: string;
}
interface State {
  isLoading: boolean;
  signInError: boolean;
  isRenewing: boolean;
  errorMessage: string;
  signInSuccess: boolean;
  user: User | null;
}

interface SignInSuccess extends User {
}

interface RefreshTokenSuccess {
  accessToken: string;
  refreshToken: string;
}

interface SignInError {
  errorMessage: string;
}

const initialState: State = {
  isLoading: false,
  isRenewing: false,
  signInError: false,
  signInSuccess: false,
  errorMessage: "",
  user: null,
};

const signInSlice = createSlice({
  name: "SignIn",
  initialState: initialState,
  reducers: {
    signInRequest: (state) => {
      state.signInError = false;
      state.signInSuccess = false;
      state.isLoading = true;
    },
    signInSuccess: (state, action: PayloadAction<SignInSuccess>) => {
      state.isLoading = false;
      state.signInSuccess = true;
      state.user = { ...action.payload };
    },
    signInError: (state, action: PayloadAction<SignInError>) => {
      state.isLoading = false;
      state.signInError = true;
      state.errorMessage = action.payload.errorMessage;
      state.user = null;
    },
    signOut: (state) => {
      state.user = null;
    },
    tokenRenewRequest: (state) => {
      state.isRenewing = true;
    },
    tokenRenewSuccess: (state, action: PayloadAction<RefreshTokenSuccess>) => {
      state.isRenewing = false;

      if (state.user) {
        state.user.accessToken = action.payload.accessToken;
        state.user.refreshToken = action.payload.refreshToken;
      }
    },
    tokenRenewError: (state) => {
      state.isRenewing = false;
      state.user = null;
    },
  },
});

const { actions } = signInSlice;

export const {
  signInError,
  signOut,
  signInRequest,
  signInSuccess,
  tokenRenewError,
  tokenRenewRequest,
  tokenRenewSuccess,
} = actions;

export const signInReducer = signInSlice.reducer;
