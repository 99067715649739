import { InputLabel, RadioInput } from 'modules/common';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/checkbox-check.svg';
import { AddOrUpdatePresentationRequest } from 'services';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface Props {
  onChange: (data: Partial<AddOrUpdatePresentationRequest>) => void;
  data: Pick<AddOrUpdatePresentationRequest, 'expertArea'> | undefined;
}

export const PresentationFormSecond: React.FC<Props> = ({ onChange, data }) => {
  const { items } = useSelector((state: RootState) => state.expertAreas);
  const [selectedField, setSelectedField] = useState(data?.expertArea || null);
  const [additionalValue, setAdditionalValue] = useState<string>(items.includes(data?.expertArea ?? '') ? '' : data?.expertArea ?? '');

  useEffect(() => {
    if (data?.expertArea) {
      onChange({ ...data, expertArea: data.expertArea });
    }
  }, [data?.expertArea]);

  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;

    setSelectedField(value);
    onChange({ ...data, expertArea: value });
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;

    setAdditionalValue(value);
    setSelectedField(value);
    onChange({ ...data, expertArea: value });
  }

  function onCustomInputClick() {
    if (additionalValue.length) return;

    onChange({ ...data, expertArea: undefined });
  }

  return (
    <>
      <InputLabel isValid labelFor="Field" labelText="Stručno područje" />
      {items.map((value, index) => {
        return (
          <div key={index} className="mb-16">
            <RadioInput
              htmlFor={`${value}${index}`}
              onChange={handleRadioChange}
              size="base"
              checked={selectedField === value}
              value={value}
              text={value}
            />
          </div>
        );
      })}
      <div className="f">
        <div className="f-align-y-center">
          <input
            type="radio"
            checked={selectedField === additionalValue}
            onChange={handleRadioChange}
            className={`radio-input checkbox`}
            value={additionalValue}
            onClick={onCustomInputClick}
          />
          <label htmlFor="additionalValue">
            <CheckIcon />
          </label>
        </div>
        <input
          id="additionalValue"
          className={`form-input form-input--large`}
          value={additionalValue}
          onChange={onInputChange}
          onClick={() => {
            onCustomInputClick();
            setSelectedField(additionalValue || '');
          }}
        />
      </div>
    </>
  );
};
