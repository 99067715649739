import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import {
    isTokenExpired,
    logOff,
    sessionCache,
    signInSuccess,
    Timer,
    tokenRenew,
    AccountHeader,
    AccountLayout,
} from "modules/auth";
import { Footer, MainLayout, RoleType } from "modules/common";
import {
    ChangePassword,
    ActivateAccount,
    Educations,
    ForgotPassword,
    SignIn,
    SignUp,
    UserActivity,
    UserAdministration,
    Profile,
    SystemAdministration,
    // Dashboard,
    NotAuthorized,
    NoMatch,
    Agrometeorology,
    AgroclimateStations,
    PlantProtection,
    Records,
    Advisors,
    Presentations,
    Communication,
    News,
    ModulesAdministration,
    ModuleVersionsAdministration,
} from "views";
import { AppRoutes } from "./AppRoutes";
import { PrivateRoute } from "routing";
import { getProfile } from "modules/dashboard";
import { getExpertAreas, getMeasures } from "modules/education";
import { Home } from "views/Home";
import { ExternalModules } from "views/ExternalModules";
import { SignInAd } from "views/SignInAd";

const timer = Timer.getInstance();
export const Routing: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const cache = sessionCache();
    const session = cache.getSession();
    const expiration = session?.expiration ?? 0;
    const refreshTokenExpiration = session?.refreshExpiration ?? 0;
    const [redirectLocation, setRedirectLocation] = useState<Partial<typeof location>>();

    useEffect(() => {
        if (session && !isTokenExpired(refreshTokenExpiration)) {
            dispatch(
                signInSuccess({
                    ...session,
                    role: { value: session.roleType.value, label: session.roleType.label },
                })
            );
            dispatch(getProfile());
            dispatch(getExpertAreas());
            dispatch(getMeasures());
        } else {
            dispatch(logOff());
            setRedirectLocation({ pathname: "/", search: "" });
        }
    }, [dispatch, refreshTokenExpiration, session]);

    useEffect(() => {
        timer.addHandler(() => {
            dispatch(tokenRenew());
        });
        timer.start(expiration);

        return () => timer.cancel();
    }, [dispatch, expiration]);

    useEffect(() => {
        setRedirectLocation(location);
    }, []);

    return (
        <Switch>
            <Route path={AppRoutes.Home} exact>
                <MainLayout>
                    <Home />
                </MainLayout>
            </Route>
            <Route path={AppRoutes.SignIn} exact>
                <AccountLayout header={<AccountHeader />} footer={<Footer />}>
                    <SignIn location={redirectLocation} />
                </AccountLayout>
            </Route>
            <Route path={AppRoutes.SignInAd} exact>
                <SignInAd />
            </Route>
            <Route path={AppRoutes.ForgotPassword} exact>
                <AccountLayout header={<AccountHeader />} footer={<Footer />}>
                    <ForgotPassword />
                </AccountLayout>
            </Route>
            <Route path={AppRoutes.ChangePassword} exact>
                <AccountLayout header={<AccountHeader />} footer={<Footer />}>
                    <ChangePassword />
                </AccountLayout>
            </Route>
            <Route path={AppRoutes.SignUp} exact>
                <AccountLayout header={<AccountHeader />} footer={<Footer />}>
                    <SignUp />
                </AccountLayout>
            </Route>
            <Route exact path={AppRoutes.ActivateAccount}>
                <ActivateAccount />
            </Route>
            <Route exact path={AppRoutes.NotAuthorized}>
                <NotAuthorized />
            </Route>
            {/* <PrivateRoute path={AppRoutes.Root} exact>
        <MainLayout>
          <Dashboard />
        </MainLayout>
      </PrivateRoute> */}
            <Route path={AppRoutes.News} exact>
                <MainLayout>
                    <News />
                </MainLayout>
            </Route>
            <Route path={AppRoutes.Agrometeorology} exact>
                <MainLayout>
                    <Agrometeorology />
                </MainLayout>
            </Route>
            <PrivateRoute
                roleRequirements={[
                    RoleType.Farmer,
                    RoleType.MpAdminFarmer,
                    RoleType.AdviserAdminFarmer,
                    RoleType.EmployeeAdviser,
                    RoleType.MpSuperAdmin,
                    RoleType.AdviserSuperAdmin,
                ]}
                path={AppRoutes.AgroclimateStations}
                exact
            >
                <MainLayout>
                    <AgroclimateStations />
                </MainLayout>
            </PrivateRoute>
            <Route path={AppRoutes.PlantProtection} exact>
                <MainLayout>
                    <PlantProtection />
                </MainLayout>
            </Route>
            <PrivateRoute path={AppRoutes.Records} exact>
                <MainLayout>
                    <Records />
                </MainLayout>
            </PrivateRoute>
            <Route path={AppRoutes.Advisors} exact>
                <MainLayout>
                    <Advisors />
                </MainLayout>
            </Route>
            <PrivateRoute
                roleRequirements={[
                    RoleType.AdviserAdminFarmer,
                    // RoleType.AdviserAdminFisherman,
                    RoleType.EmployeeAdviser,
                    RoleType.AdviserSuperAdmin,
                    RoleType.EmployeeApprrr,
                    RoleType.MpSuperAdmin,
                ]}
                path={AppRoutes.Presentations}
                exact
            >
                <MainLayout>
                    <Presentations />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[
                    RoleType.EmployeeMp,
                    RoleType.MpSuperAdmin,
                    // RoleType.MpAdminFisherman,
                    RoleType.MpAdminFarmer,
                    RoleType.EmployeeAdviser,
                    RoleType.AdviserSuperAdmin,
                    // RoleType.AdviserAdminFisherman,
                    RoleType.AdviserAdminFarmer,
                ]}
                path={AppRoutes.UserAdministration}
                exact
            >
                <MainLayout>
                    <UserAdministration />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[
                    // RoleType.Fisherman,
                    RoleType.Farmer,
                    // RoleType.AdviserAdminFisherman,
                    RoleType.EmployeeAdviser,
                    RoleType.AdviserSuperAdmin,
                    RoleType.AdviserAdminFarmer,
                    RoleType.MpSuperAdmin,
                ]}
                path={AppRoutes.Education}
                exact
            >
                <MainLayout>
                    <Educations />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[
                    RoleType.MpSuperAdmin,
                    RoleType.AdviserAdminFarmer,
                    RoleType.MpAdminFarmer,
                    RoleType.AdviserSuperAdmin,
                ]}
                path={AppRoutes.UserActivity}
                exact
            >
                <MainLayout>
                    <UserActivity />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={AppRoutes.Profile} exact>
                <MainLayout>
                    <Profile />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[RoleType.MpSuperAdmin]}
                path={AppRoutes.SystemAdministration}
                exact
            >
                <MainLayout>
                    <SystemAdministration />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[
                    RoleType.MpSuperAdmin,
                    RoleType.AdviserAdminFarmer,
                    RoleType.MpAdminFarmer,
                    // RoleType.MpAdminFisherman,
                    RoleType.AdviserSuperAdmin,
                    // RoleType.AdviserAdminFisherman,
                ]}
                path={AppRoutes.Communication}
                exact
            >
                <MainLayout>
                    <Communication />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[RoleType.MpSuperAdmin]}
                path={AppRoutes.ModulesAdministration}
                exact
            >
                <MainLayout>
                    <ModulesAdministration />
                </MainLayout>
            </PrivateRoute>
            <PrivateRoute
                roleRequirements={[RoleType.MpSuperAdmin]}
                path={AppRoutes.ModuleVersionsAdministration}
                exact
            >
                <MainLayout>
                    <ModuleVersionsAdministration />
                </MainLayout>
            </PrivateRoute>
            <Route path={AppRoutes.ExternalModules} exact>
                <MainLayout>
                    <ExternalModules />
                </MainLayout>
            </Route>
            <Route>
                <NoMatch />
            </Route>
        </Switch>
    );
};
