import { useTitle } from "modules/common";
import { useOuterModules } from "modules/outerModules";
import { GetOuterModuleRequest } from "services";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Overlay } from "modules/common/components/Overlay";
import { PanelMenuItem } from "modules/common/components/PanelMenu";

const initialRequest: GetOuterModuleRequest = {
    page: 1,
    pageSize: 1000,
    search: null,
    isActive: null,
    isPublic: false,
};

export const ExternalModules = () => {
    useTitle("Dodatni moduli - eSavjetnik");
    const { data: outerModules } = useOuterModules(initialRequest);
    const previousLogin = useSelector((state: RootState) => state.signIn.user?.previousLogin);

    const menuItems: PanelMenuItem[] =
        outerModules?.items.map(item => ({
            label: item.name,
            link: item.url,
            newModule: Boolean(previousLogin) && new Date(item.created) > new Date(previousLogin!),
        })) ?? [];

    return (
        <Overlay
            menuItems={menuItems}
            selectFirstMenuItemWhenAvailable
            titleBar={{
                fullScreenButton: true,
                newWindowButton: true,
            }}
            view={{
                title: "Dodatni moduli",
                source: item => item?.link ?? "",
            }}
        />
    );
};
