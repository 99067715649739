export enum AgrometeorologyRoutes {
    WeatherToday = "weatherToday",
    CityWeatherForecast = "cityWeatherForecast",
    AgrometeorologyForecast = "agrometeorologyForecast",
    CloudMovement = "cloudMovement",
    WindAndTemperatureMap = "windAndTemperatureMap",
}

export enum NewsRoutes {
    News = "/vijesti-i-savjeti/vijesti",
    Advice = "/vijesti-i-savjeti/savjeti",
}

export enum PlantProtectionRoutes {
    IntensityOfPestAttacks = "/zastita-bilja/intenzitet-napada-stetnika",
    FirstAppearanceOfPests = "/zastita-bilja/prve-pojave-stetnika",
    PestPopulationSize = "/zastita-bilja/brojnost-populacije-stetnika",
    FlavescenceDoreeMap = "/karta-zlatne-zutice",
    DiseasesAndPests = "/zastita-bilja/bolesti-stetnici",
    Recommendations = "/zastita-bilja/preporuke",
    CollectionOfPackagingWaste = "/zastita-bilja/sakupljanje-otpada",
}
