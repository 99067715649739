import { useAccessToken, useStandardErrorHandler } from "modules/common";
import { useMutation, UseMutationOptions, useQueries, useQueryClient } from "@tanstack/react-query";
import {
    FilterPreference,
    getFilterPreferences,
    updateFilterPreferences,
    UpdateFilterPreferencesRequest,
} from "services/account";

export const useGetFilterPreferencesQuery = (filterNames: string[]) => {
    const accessToken = useAccessToken();
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju dokumenta");

    return useQueries({
        queries: filterNames.map(filterName => ({
            queryKey: ["filterPreferences", filterName],
            queryFn: () => getFilterPreferences(accessToken!, filterName),
            enabled: Boolean(accessToken),
            onError: errorHandler,
        })),
    });
};

export const useUpdateFilterPreferences = (
    options?: UseMutationOptions<FilterPreference, unknown, UpdateFilterPreferencesRequest>
) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();
    const errorHandler = useStandardErrorHandler("Greška pri ažuriranju filtera");

    return useMutation({
        mutationFn: (data: UpdateFilterPreferencesRequest) =>
            updateFilterPreferences(data, accessToken!),
        onError: errorHandler,
        onSuccess: () => {
            queryClient.invalidateQueries(["filterPreferences"]);
        },
        ...options,
    });
};
