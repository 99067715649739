import React from "react";
import { ReactComponent as UserIcon } from "assets/icons/profile-image.svg";

interface Props {
    image?: string;
    className?: string;
}

export const ProfileImage: React.FC<Props> = ({ image, className }) => (
    <div className={`profile-image ${className ?? ""}`}>
        {image ? <img width={22} src={image} alt="profile" /> : <UserIcon />}
    </div>
);
