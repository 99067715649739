import { xor } from "lodash";
import {
  Checkbox,
  Filter,
  FilterGroup,
  Loading,
  ToggleSwitch,
  useOutsideClick,
} from "modules/common";
import {
  getExpertAreas,
  getMeasures,
  getOperationTypes,
} from "modules/education";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { FileType } from "..";

interface Props {
  typeFilter: string[];
  setTypeFilter: React.Dispatch<React.SetStateAction<string[]>>;
  expertAreasFilter: string[];
  setExpertAreasFilter: React.Dispatch<React.SetStateAction<string[]>>;
  measuresFilter: string[];
  setMeasuresFilter: React.Dispatch<React.SetStateAction<string[]>>;
  operationsFilter: string[];
  setOperationsFilter: React.Dispatch<React.SetStateAction<string[]>>;
  isActiveFilter: boolean;
  setIsActiveFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PresentationFilters: React.FC<Props> = ({
  typeFilter,
  setTypeFilter,
  expertAreasFilter,
  setExpertAreasFilter,
  measuresFilter,
  setMeasuresFilter,
  operationsFilter,
  setOperationsFilter,
  isActiveFilter,
  setIsActiveFilter,
}) => {
  const dispatch = useDispatch();
  const typeFilterRef = useRef<HTMLDivElement>(null);
  const expertAreasFilterRef = useRef<HTMLDivElement>(null);
  const measuresFilterRef = useRef<HTMLDivElement>(null);
  const operationsFilterRef = useRef<HTMLDivElement>(null);

  const { items: ReducerMeasures, isLoading: loadingMeasures } = useSelector(
    (state: RootState) => state.measures
  );
  const { items: ReducerOperationTypes, isLoading: loadingOperationTypes } =
    useSelector((state: RootState) => state.operationTypes);
  const { items: ReducerExpertAreas, isLoading: loadingExpertAreas } =
    useSelector((state: RootState) => state.expertAreas);

  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showExpertAreasFilter, setShowExpertAreasFilter] = useState(false);
  const [showMeasuresFilter, setShowMeasuresFilter] = useState(false);
  const [showOperationsFilter, setShowOperationsFilter] = useState(false);

  function onTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedType = FileType?.find((i) => i.type === event.target.value);

    if (!selectedType) {
      return;
    }

    // Create array of unique values
    const newArray = xor(typeFilter, [selectedType.type]);
    setTypeFilter(newArray);
  }

  function onExpertAreaChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedExpertArea = ReducerExpertAreas?.find(
      (i) => i === event.target.value
    );

    if (!selectedExpertArea) {
      return;
    }

    // Create array of unique values
    const newArray = xor(expertAreasFilter, [selectedExpertArea]);
    setExpertAreasFilter(newArray);
  }

  function onMeasureChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedMeasure = ReducerMeasures?.find(
      (i) => i === event.target.value
    );

    if (!selectedMeasure) {
      return;
    }

    // Create array of unique values
    const newArray = xor(measuresFilter, [selectedMeasure]);
    setMeasuresFilter(newArray);
  }

  function onOperationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedOperation = ReducerOperationTypes?.find(
      (i) => i === event.target.value
    );

    if (!selectedOperation) {
      return;
    }

    // Create array of unique values
    const newArray = xor(operationsFilter, [selectedOperation]);
    setOperationsFilter(newArray);
  }

  const handleTypeFilterOutsideClick = () => {
    setShowTypeFilter(false);
  };

  const handleExpertAreasFilterOutsideClick = () => {
    setShowExpertAreasFilter(false);
  };

  const handleMeasuresFilterOutsideClick = () => {
    setShowMeasuresFilter(false);
  };
  const handleOperationsFilterOutsideClick = () => {
    setShowOperationsFilter(false);
  };

  useOutsideClick(typeFilterRef, handleTypeFilterOutsideClick);
  useOutsideClick(expertAreasFilterRef, handleExpertAreasFilterOutsideClick);
  useOutsideClick(measuresFilterRef, handleMeasuresFilterOutsideClick);
  useOutsideClick(operationsFilterRef, handleOperationsFilterOutsideClick);

  const handleTypeFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setTypeFilter([]);
  };

  const handleExpertAreasFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setExpertAreasFilter([]);
  };

  const handleMeasuresFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setMeasuresFilter([]);
  };

  const handleOperationTypesFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setOperationsFilter([]);
  };

  const handleTypeFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowTypeFilter(!showTypeFilter);
  };

  const handleExpertAreasFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowExpertAreasFilter(!showExpertAreasFilter);

    if (!ReducerExpertAreas.length) {
      dispatch(getExpertAreas());
    }
  };

  const handleMeasuresFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowMeasuresFilter(!showMeasuresFilter);

    if (!ReducerMeasures.length) {
      dispatch(getMeasures());
    }
  };

  const handleOperationTypesFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowOperationsFilter(!showOperationsFilter);

    if (!ReducerOperationTypes.length) {
      dispatch(getOperationTypes());
    }
  };

  return (
    <FilterGroup className="mb-44" text="Filteri">
      <div ref={typeFilterRef}>
        <Filter
          isActive={!!typeFilter.length}
          showDropdown={showTypeFilter}
          onClear={handleTypeFilterClear}
          onClick={handleTypeFilterClick}
          text="Vrsta"
        >
          {FileType.map((value, index) => (
            <div key={index}>
              <Checkbox
                type="checkbox"
                value={value.type}
                checked={Boolean(typeFilter.includes(value.type))}
                onChange={onTypeChange}
                htmlFor={value.type}
                text={<div className="filter__item__text">{value.type}</div>}
              />
            </div>
          ))}
        </Filter>
      </div>

      <div ref={expertAreasFilterRef}>
        <Filter
          isActive={!!expertAreasFilter.length}
          showDropdown={showExpertAreasFilter}
          onClear={handleExpertAreasFilterClear}
          onClick={handleExpertAreasFilterClick}
          text="Stručno podr."
        >
          {loadingExpertAreas ? (
            <Loading />
          ) : (
            ReducerExpertAreas.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value}
                  checked={Boolean(expertAreasFilter.includes(value))}
                  onChange={onExpertAreaChange}
                  htmlFor={value}
                  text={<div className="filter__item__text">{value}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div ref={measuresFilterRef}>
        <Filter
          isActive={!!measuresFilter.length}
          showDropdown={showMeasuresFilter}
          onClear={handleMeasuresFilterClear}
          onClick={handleMeasuresFilterClick}
          text="Mjera"
        >
          {loadingMeasures ? (
            <Loading />
          ) : (
            ReducerMeasures.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value}
                  checked={Boolean(measuresFilter.includes(value))}
                  onChange={onMeasureChange}
                  htmlFor={value}
                  text={<div className="filter__item__text">{value}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div ref={operationsFilterRef}>
        <Filter
          isActive={!!operationsFilter.length}
          showDropdown={showOperationsFilter}
          onClear={handleOperationTypesFilterClear}
          onClick={handleOperationTypesFilterClick}
          text="Operacija"
        >
          {loadingOperationTypes ? (
            <Loading />
          ) : (
            ReducerOperationTypes.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value}
                  checked={Boolean(operationsFilter.includes(value))}
                  onChange={onOperationChange}
                  htmlFor={value}
                  text={<div className="filter__item__text">{value}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div className="f f-align-items-center">
        <ToggleSwitch
          htmlFor="active"
          value={isActiveFilter.toString()}
          onChange={() => setIsActiveFilter(!isActiveFilter)}
          checked={isActiveFilter}
          className="mr-12"
        />
        <label
          htmlFor="active"
          // className="subscription-row__label"
        >
          Aktivni
        </label>
      </div>
    </FilterGroup>
  );
};
