import React, { PropsWithChildren, useRef, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { useOutsideClick } from "modules/common";
import { useElementRendered } from "helpers/DomHelper";

interface Props {
    name: string;
    label: string;
    className?: string;
}
export const NavDropdown: React.FC<PropsWithChildren<Props>> = ({ className, name, label, children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);

    useOutsideClick(ref, () => setShow(false));

    const dropdownClass = `nav-${name}`; 
    const hasActiveItem = useElementRendered(`.${dropdownClass} .nav__item--active`);

    return (
        <div className={`${dropdownClass} nav-dropdown${hasActiveItem ? " nav-dropdown--active" : ""}`} ref={ref}>
            <div
                className={`nav-dropdown__title ${className ?? ""}`}
                onClick={() => setShow(!show)}
            >
                <span className="nav-dropdown__text">{label}</span>
                <ArrowDownIcon className={`${show && "nav-dropdown__icon--active"}`} />
            </div>
            <div className={`nav-dropdown__container${show ? "" : " nav-dropdown--hidden"}`}>{children}</div>
        </div>
    );
};
