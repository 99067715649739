import { useTitle } from "modules/common";
import React from "react";
import { Overlay } from "modules/common/components/Overlay";

export const Records: React.FC = () => {
    useTitle("Evidencije - eSavjetnik");

    return (
        <Overlay
            fullScreenInitially
            view={{
                title: "Evidencije",
                source: "documents",
            }}
        />
    );
};
