import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useTitle } from "modules/common";
import { useDispatch } from "react-redux";

import { AppRoutes } from "routing";
import { sessionCache, signInUser } from "modules/auth";

export const SignInAd: React.FC = () => {
    useTitle("Prijava - eSavjetnik");

    const dispatch = useDispatch();
    const session = sessionCache().getSession();

    useEffect(() => {
        if (!session) {
            dispatch(signInUser({}));
        }
    }, []);

    return <Redirect to={AppRoutes.Home} />;
};
