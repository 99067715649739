import { sessionCache } from "modules/auth";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const useAccessToken = () => {
    const accessToken = useSelector((state: RootState) => state.signIn.user?.accessToken);
    if (accessToken) return accessToken;
    const session = sessionCache().getSession();
    return session?.accessToken;
};
