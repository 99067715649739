// @ts-nocheck
import { Dropdown, FormGroup, InputLabel, RoleType } from 'modules/common';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { EducationFormInfo } from '../models';
import { emailRegex, phoneRegex } from 'modules/common';
import { sessionCache } from 'modules/auth';

const isFarmer = (roleType: number) => RoleType.Farmer === roleType;

interface Props {
  onChange: (data: EducationFormInfo) => void;
  data: EducationFormInfo;
  formValid: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  isSubmitAndAddNew?: boolean;
}

export const EducationMembersForm: React.FC<Props> = ({
  onChange,
  data,
  formValid,
  isSubmitAndAddNew,
}) => {
  const { profile } = useSelector((state: RootState) => state.profile);
  const { farmMembers, firstFarmMember } = useSelector(
    (state: RootState) => state.farmHolder
  );

  const user = sessionCache().getSession();
  const roleType = user?.roleType.value ?? profile?.roleType?.value;

  const {
    register,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm<EducationFormInfo>({
    mode: 'onChange',
    defaultValues: {
      ...data,
    },
  });

  const memberList = farmMembers?.farmMembers?.map((data: any) => {
    const item = {
      label: `${data.firstName} ${data.lastName}`,
      value: data,
    };

    return item;
  });

  function handleChange() {
    const data = getValues();
    onChange(data);
  }

  function onMemberChange(data: { label: string; value: any } | null) {
    const selectedMember = farmMembers?.farmMembers?.find(
      (i: any) => i === data?.value
    );

    if (!selectedMember) {
      return;
    }

    onChange({
      firstName: selectedMember.firstName,
      lastName: selectedMember.lastName,
      oib: selectedMember.oib,
      email: null,
      mobileNumber: null,
      mibpg: '',
    });
  }

  useEffect(() => {
    formValid(isValid);
  }, [isValid, formValid]);

  useEffect(() => {
    if (!isSubmitAndAddNew) return;
    reset();
  }, [isSubmitAndAddNew, reset]);

  return (
    <form className="educations_modal-form" onChange={handleChange}>
      {farmMembers?.farmMembers?.length ? (
        <FormGroup>
          <InputLabel
            labelFor="member"
            labelText="Odaberite člana"
            isValid={true}
          />
          <Dropdown
            id="member"
            defaultValue={{
              label: `${data.firstName} ${data.lastName}`,
              value: data,
            }}
            value={{
              label: `${firstFarmMember?.firstName} ${firstFarmMember?.lastName}`,
              value: firstFarmMember,
            }}
            maxMenuHeight={170}
            size="large"
            isValid={true}
            errorMessage="Nema članova"
            noOptionsMessage={() => 'Nema članova'}
            options={memberList}
            onChange={onMemberChange}
            isSearchable
            placeholder=""
          />
        </FormGroup>
      ) : null}
      {!isFarmer(roleType ?? RoleType.Farmer) ? (
        <FormGroup>
          <InputLabel
            labelFor="mibpg"
            labelText="MIBPG"
            isValid={!errors.mibpg}
          />
          <input
            value={data.mibpg}
            className={`form-input form-input--large ${
              errors.mibpg && 'form-input--error'
            }`}
            {...register('mibpg', {
              required: true,
              pattern: /^[0-9]*$/,
            })}
          />
        </FormGroup>
      ) : null}
      <FormGroup>
        <InputLabel
          labelFor="firstName"
          labelText="Ime"
          isValid={!errors.firstName}
        />
        <input
          value={data.firstName}
          className={`form-input form-input--large 
          ${errors.firstName && 'form-input--error'}
          ${
            isFarmer(roleType ?? RoleType.Farmer) &&
            'educations__modal__disabled-input'
          } 
          `}
          {...register('firstName', { required: true })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel
          labelFor="lastName"
          labelText="Prezime"
          isValid={!errors.lastName}
        />
        <input
          value={data.lastName}
          className={`form-input form-input--large 
          ${errors.lastName && 'form-input--error'}
          ${
            isFarmer(roleType ?? RoleType.Farmer) &&
            'educations__modal__disabled-input'
          } 
          `}
          {...register('lastName', { required: true })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel labelFor="oib" labelText="OIB" isValid={!errors.oib} />
        <input
          value={data.oib}
          className={`form-input form-input--large 
          ${errors.oib && 'form-input--error'}
          ${
            isFarmer(roleType ?? RoleType.Farmer) &&
            'educations__modal__disabled-input'
          }  
          `}
          {...register('oib', {
            required: true,
            pattern: /^\d{11}$/,
          })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel
          labelFor="email"
          labelText="E-mail adresa"
          isValid={!errors.email}
        />
        <input
          value={data.email || ''}
          className={`form-input form-input--large ${
            errors.email && 'form-input--error'
          }`}
          {...register('email', {
            pattern: emailRegex,
          })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel
          labelFor="mobileNumber"
          labelText="Broj telefona"
          isValid={!errors.mobileNumber}
        />
        <input
          value={data.mobileNumber || ''}
          className={`form-input form-input--large ${
            errors.mobileNumber && 'form-input--error'
          }`}
          {...register('mobileNumber', {
            pattern: phoneRegex,
          })}
        />
      </FormGroup>
    </form>
  );
};
