import React, { useRef, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { useOutsideClick } from "modules/common";
import { useDispatch, useSelector } from "react-redux";
import { logOff } from "modules/auth";
import { Link } from "react-router-dom";
import { AppRoutes } from "routing";
import { RootState } from "store";
import { ProfileImage } from "./ProfileImage";

export const ProfileSettings: React.FC = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state: RootState) => state.profile.profile);

    const [isOpen, setIsOpen] = useState(false);

    const handleMenuClick = () => {
        setIsOpen(prev => !prev);
    };

    const handleOutsideClick = () => {
        setIsOpen(false);
    };
    const profileSettingsrRef = useRef<HTMLDivElement>(null);

    const handleLogout = () => {
        dispatch(logOff());
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useOutsideClick(profileSettingsrRef, handleOutsideClick);
    return (
        <div ref={profileSettingsrRef} onClick={handleMenuClick} className="settings-container">
            <ProfileImage />
            <div className="profile-wrapper">
                <ArrowDownIcon />
                <ul
                    className={`profile__dropdown profile__items ${
                        isOpen ? "profile__dropdown--active" : ""
                    }`}
                >
                    <li className="profile__header">
                        <ProfileImage className="mr-8" />
                        <span>
                            {profile?.firstName} {profile?.lastName}
                        </span>
                    </li>
                    <li onClick={handleClose} className="profile__item">
                        <Link to={AppRoutes.Profile}>Postavke</Link>
                    </li>
                    {/* <li onClick={handleClose} className="profile__item">
            <Link to={AppRoutes.News}>Upravljačka konzola</Link>
          </li> */}

                    <li className="profile__item" onClick={handleLogout}>
                        Odjava
                    </li>
                </ul>
            </div>
        </div>
    );
};
