import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useStandardErrorHandler } from "modules/common";
import { getWeatherForecastCities } from "./iot";
import { ForecastCityDto } from "./models";

export const useForecastCities = (options?: UseQueryOptions<ForecastCityDto[]>) => {
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju gradova");

    return useQuery({
        queryKey: ["agrometeorology", "cities"],
        queryFn: () => getWeatherForecastCities(),
        onError: errorHandler,
        ...options,
    });
};
