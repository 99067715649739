export const getISODateFormat = (date: Date) => {
    if (!date) return undefined;
    const yyyy: string = date.getFullYear().toString();
    const mm: string = (date.getMonth() + 1).toString().padStart(2, "0");
    const dd: string = date.getDate().toString().padStart(2, "0");
    // Date part of ISO standard (yyyy-MM-dd)
    return `${yyyy}-${mm}-${dd}`;
};

// This method should only be used for displaying dates.
// Do not use as date transport format when sending data to backend.
export const getDisplayDateFormat = (
    dateString?: string | Date,
    includeTime: boolean = false,
    localeFormat: string = "hr-HR",
    timezone?: string
): string => {
    if (!dateString) {
        return "";
    }
    const parsedDate = new Date(dateString);
    return includeTime
        ? parsedDate.toLocaleString(localeFormat, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              timeZone: timezone,
          })
        : parsedDate.toLocaleDateString(localeFormat, {
              timeZone: timezone,
          });
};

// Also should be used only for displaying dates.
// Do not use as date transport format when sending data to backend.
export const getLongDisplayDateFormat = (dateString?: string | Date): string => {
    const longMonths = [
        "siječnja",
        "veljače",
        "ožujka",
        "travnja",
        "svibnja",
        "lipnja",
        "srpnja",
        "kolovoza",
        "rujna",
        "listopada",
        "studenog",
        "prosinca",
    ];

    if (!dateString) {
        return "";
    }

    const parsedDate = new Date(dateString);
    const day = parsedDate.getDate();
    const month = longMonths[parsedDate.getMonth() - 1];
    const year = parsedDate.getFullYear();

    return `${day}. ${month} ${year}.`;
};

// Helper method. Just a wrapper around getDisplayDateFormat with prespecified UTC timezone.
// The only benefit is slightly simpler syntax when only timezone should be non-default in getDisplayDateFormat.
export const getDisplayDateFormatUTC = (
    dateString?: string | Date,
    includeTime: boolean = false,
    localeFormat: string = "hr-HR"
): string => {
    return getDisplayDateFormat(dateString, includeTime, localeFormat, "UTC");
};

export type TimeUnit = "year" | "day" | "hour" | "minute" | "second" | "millisecond";

const getDivider = (unit: Exclude<TimeUnit, "year">) => {
    let divider = 1;
    if (unit === "millisecond") return divider;
    divider *= 1000;
    if (unit === "second") return divider;
    divider *= 60;
    if (unit === "minute") return divider;
    divider *= 60;
    if (unit === "hour") return divider;

    return divider * 24;
};

export const getDateDiff = (start: Date, end: Date, unit: TimeUnit = "millisecond") => {
    if (unit === "year") {
        return end.getFullYear() - start.getFullYear();
    } else {
        let diff = end.getTime() - start.getTime();
        return Math.abs(diff / getDivider(unit));
    }
};

export const getDayOfWeek = (dateString: string) => {
    return new Date(dateString).toLocaleString("en-us", {
        weekday: "long",
    });
};
