import * as React from "react";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { SkeletonText } from "modules/common";
interface Props {
    title: string;
    onClose: () => void;
    isLoading?: boolean;
}

export const ModalHeader: React.FC<Props> = ({ title, onClose, isLoading }) => {
    return (
        <div className="modal-header">
            {isLoading ? (
                <SkeletonText width="40%" height="20px" />
            ) : (
                <span className="modal-header-title">{title.toLocaleUpperCase()}</span>
            )}
            <CloseIcon onClick={onClose} className="modal-header-icon" />
        </div>
    );
};
