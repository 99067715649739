import { AxiosResponse } from "axios";
import { clearAbility, decodeJwt, sessionCache, Timer } from "modules/auth";
import { completeSignIn } from "modules/auth/utils/completeSignIn";
import { renewToken, signIn, signInAd, signInEpToken, SignInResponse } from "services";
import { ADSignInRequest, EPSignInRequest, SignInRequest } from "services/auth/request";
import { AppDispatch, AppThunk } from "store";

import {
  signInError,
  signInRequest,
  tokenRenewError,
  tokenRenewRequest,
  tokenRenewSuccess,
  signOut,
} from "./reducer";

export const tokenRenew = (): AppThunk => async (dispatch) => {
  try {
    dispatch(tokenRenewRequest());

    const cache = sessionCache();
    const timer = Timer.getInstance();
    const session = cache.getSession();

    if (session) {
      const response = await renewToken(session.refreshToken);
      const body = decodeJwt(response.data.accessToken);

      cache.updateTokens(response.data.accessToken, response.data.refreshToken);
      timer.start(body.exp);

      dispatch(
        tokenRenewSuccess({
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        })
      );
    } else {
      dispatch(logOff());
    }
  } catch (error) {
    dispatch(tokenRenewError());
    dispatch(logOff());
    dispatch(
      signInError({ errorMessage: "Sesija istekla, ponovno se prijavite." })
    );
  }
};

export const signInUser = (data: SignInRequest | EPSignInRequest | ADSignInRequest): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(signInRequest());

    let response : AxiosResponse<SignInResponse>;
    let rememberMe = false;

    if ("token" in data) {
      response = await signInEpToken(data.token, data.returnUrl);
    } else if ("username" in data) {
      response = await signIn(data);
      rememberMe = data.rememberMe;
    } else {
      response = await signInAd();
    }

    completeSignIn(
      dispatch as AppDispatch,
      response.data.accessToken,
      response.data.refreshToken,
      rememberMe);
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(signInError({ errorMessage: first.message }));
  }
};

export const logOff = (): AppThunk => async (dispatch) => {
  const cache = sessionCache();
  cache.removeSession();
  clearAbility();
  dispatch(signOut());
};
