import React from "react";
import ReactTooltip, { Type } from "react-tooltip";

interface Props {
  tooltipText: string;
  tooltipPosition?: "top" | "right" | "bottom" | "left";
  id: string;
  type?: Type;
}

export const Tooltip: React.FC<Props> = ({
  tooltipPosition,
  tooltipText,
  id,
  type = "dark",
  children
}) => {
  return (
    <>
      <p data-for={id} className="tooltip-target-wrapper" data-tip={tooltipText}>
        {children}
      </p>
      <ReactTooltip
        className="tooltip"
        place={tooltipPosition}
        effect="solid"
        type={type}
        id={id}
      />
    </>
  );
};
