import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForFarmer = (abilityBuilder: AbilityBuilder<AppAbility>) => {
    const { can, cannot, rules } = abilityBuilder;

    cannot("view", "CourseList");
    cannot("view", "FarmList");
    cannot("update", "UserRole");

    can("view", "EducationModule");
    can("view", "CourseRegistration");
    can("view", "AgroclimateStations");

    can("view", "Records");
    can("view", "ExternalModules");

    can("view", "WeatherAlarmToggle");

    return rules;
};
