import { UserAccount } from "services";
import { mapCounty, mapNotifications, mapSubscriptions, mapUserRole } from "./mappingUtils";

export const mapUserAccount = (userAccount: UserAccount) => {
    const county = mapCounty(userAccount.countyId);
    const userRole = mapUserRole(userAccount.roleType);
    const notifications = mapNotifications(userAccount.notificationType);
    const subscriptions = mapSubscriptions(userAccount.infoType);

    return {
        uid: userAccount.uid ?? "",
        mibpg: userAccount.mibpg ?? "",
        email: userAccount.email ?? "",
        username: userAccount.username ?? "",
        legalEntityName: userAccount.legalEntityName ?? "",
        firstName: userAccount.firstName ?? "",
        lastName: userAccount.lastName ?? "",
        street: userAccount.street ?? "",
        streetNumber: userAccount.streetNumber ?? "",
        postalNumber: userAccount.postalNumber ?? "",
        city: userAccount.city ?? "",
        country: userAccount.country ?? "",
        mobileNumber: userAccount.mobileNumber ?? "",
        county: county?.label ?? "",
        roleType: userAccount.roleType,
        userRole,
        notificationType: notifications,
        infoType: subscriptions,
        isActive: userAccount.isActive,
    };
};

export type MappedUserAccount = ReturnType<typeof mapUserAccount>;
