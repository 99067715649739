import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForCitizen = (
  abilityBuilder: AbilityBuilder<AppAbility>
) => {
  const { can, cannot, rules } = abilityBuilder;

  can("view", "CourseList");
  can("view", "ExternalModules");

  cannot("view", "FarmList");
  cannot("update", "UserRole");
  cannot("view", "EducationModule");
  cannot("view", "Records");

  return rules;
};