import React, { useState } from "react";
import { HamburgerMenu } from "../HamburgerMenu";
import { MainFooter } from "../MainFooter";
import { MainHeader } from "../MainHeader/MainHeader";
import { ToastContainer } from "react-toastify";
import NiasHeader from "modules/auth/components/NiasHeader/NiasHeader";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const MainLayout: React.FC = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const signIn = useSelector((state: RootState) => state.signIn);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };
    return (
        <>
            <NiasHeader />
            <div
                className={
                    signIn.signInSuccess ? "layout-container-logged" : "layout-container-not-logged"
                }
            >
                <div className="layout-grid">
                    <div className="layout-header">
                        <MainHeader setMenuOpen={handleMenuOpen} />
                    </div>
                    <HamburgerMenu
                        isOpen={menuOpen}
                        className={`${menuOpen ? "hamburger-menu--open" : ""}`}
                        close={handleMenuClose}
                    />
                    <main className="layout-content container-x">{children}</main>
                    <div className="layout-footer">
                        <MainFooter />
                    </div>
                </div>
                <ToastContainer position="top-center" />
            </div>
        </>
    );
};
