import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { sessionCache } from "modules/auth";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "routing/AppRoutes";
import { RootState } from "store";

interface Props extends RouteProps {
  roleRequirements?: number[];
}
export const PrivateRoute: React.FC<Props> = (props) => {
  const { roleRequirements, children, ...rest } = props;
  const history = useHistory();
  const location = useLocation();
  const session = sessionCache().getSession();
  const user = useSelector((state: RootState) => state.signIn.user);

  useEffect(() => {
    if (!session) {
      history.push(AppRoutes.Home);
    }
  }, [history, session, user, location]);

  useEffect(() => {
    if (
      session &&
      roleRequirements &&
      !roleRequirements.includes(session?.roleType.value)
    ) {
      history.push(AppRoutes.NotAuthorized);
    }
  }, [history, roleRequirements, session, location]);

  return <Route {...rest} render={() => children} />;
};
