import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForAdviserAdminFarmer = (abilityBuilder: AbilityBuilder<AppAbility>) => {
    const { can, rules, cannot } = abilityBuilder;

    cannot("update", "UserRole");

    can("view", "UserAdministrationModule");
    can("view", "PresentationsModule");
    can("view", "CommunicationModule");
    can("view", "AdditionalModules");

    can("view", "EducationModule");
    can("view", "CourseRegistration");
    can("view", "EducationLinks");
    can("view", "CourseList");
    can("view", "FarmList");

    can("view", "AgroclimateStations");

    can("view", "SEMIS");
    can("view", "InternalDocumentation");
    can("view", "PIO");
    can("view", "Webtop");
    can("view", "ReportProblem");

    can("view", "SuperAdminFilter");
    can("view", "InternalSystemsModule");
    can("view", "Records");

    can("view", "WeatherAlarmToggle");

    return rules;
};
