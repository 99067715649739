import { completeSignIn } from "modules/auth/utils/completeSignIn";
import { signUp, SignUpRequest } from "services";
import { AppDispatch, AppThunk } from "store";
import {
  registrationError,
  registrationRequest,
  registrationSuccess,
} from "./reducer";

export const registerUser = (data: SignUpRequest): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(registrationRequest());

    const response = await signUp(data);
    const [first] = response.data.statuses;
    dispatch(registrationSuccess({ successMessage: first.message }));
    completeSignIn(
      dispatch as AppDispatch,
      response.data.accessToken,
      response.data.refreshToken);
  } catch (error: any) {
    const [first] = error.response.data.statuses;
    dispatch(registrationError({ errorMessage: first.message }));
  }
};
