import * as React from "react";

interface Props {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    htmlFor: string;
    className?: string;
    value?: string;
    isDisabled?: boolean;
    valueNames?: Record<string, string>;
}

export const ToggleSwitch: React.FC<Props> = ({
    checked,
    isDisabled,
    className,
    onChange,
    htmlFor,
    value,
    valueNames,
}) => {
    return (
        <>
            <label htmlFor={htmlFor} className={`toggle ${className ?? ""}`}>
                <input
                    className={`toggle__input ${isDisabled ? "toggle__input--disabled" : ""}`}
                    type="checkbox"
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    id={htmlFor}
                    disabled={isDisabled}
                />
                <div className={`toggle__fill ${isDisabled ? "toggle--disabled" : ""}`}></div>
                <span>{valueNames?.[value ?? ""]}</span>
            </label>
        </>
    );
};
