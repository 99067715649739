import React, { useState } from "react";
import { TermsOfUseModal } from "modules/auth";

export const MainFooter: React.FC = () => {
    const [showToUModal, setShowToUModal] = useState(false);
    const toggleToUModal = () => {
        setShowToUModal(!showToUModal);
    };

    return (
        <footer className="main-footer container-x">
            <span className="main-footer-left">
                <a href="https://poljoprivreda.gov.hr/" rel="noreferrer" target="_blank">
                    Ministarstvo poljoprivrede
                </a>
                <span> {new Date().getFullYear()}. Sva prava pridržana.</span>
            </span>

            <div className="main-footer-middle">
                <a href="https://www.savjetodavna.hr/" rel="noreferrer" target="_blank">
                    Savjetodavni portal
                </a>
                -
                <a href="https://www.apprrr.hr/" rel="noreferrer" target="_blank">
                    APPRRR
                </a>
                -
                <a href="https://pitanja.mps.hr" rel="noreferrer" target="_blank">
                    Postavi upit
                </a>
            </div>
            <div className="main-footer-right">
                <a onClick={toggleToUModal}>Izjava o pristupačnosti</a>-
                <a
                    onClick={() => {
                        /*TODO*/
                    }}
                >
                    Uvjeti korištenja
                </a>
            </div>
            <TermsOfUseModal toggleModal={toggleToUModal} isVisible={showToUModal} />
        </footer>
    );
};
