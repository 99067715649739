export const userActivityColumns = [
    {
        Header: "PODACI O KORISNIKU",
        accessor: (data: any) => (
            <div>
                <span>
                    {data.firstName} {data.lastName}
                </span>
            </div>
        ),
    },

    {
        Header: "APLIKACIJA",
        accessor: "application",
        className: "application-header",
    },
    {
        Header: "ULOGA",
        accessor: "userRole",
        className: "role-header",
    },
    {
        Header: "AKCIJA",
        accessor: (data: any) => <span className="user-action">{data.userAction}</span>,
    },
];
