import React from "react";
import { Navigation } from "../Navigation";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import { ProfileSettings } from "../ProfileSettings";
import { Link } from "react-router-dom";
import { AppRoutes } from "routing";
import { useSelector } from "react-redux";
import { RootState } from "store";
import LogoEAdviser from "../../../../assets/images/LogoESavjetnik.svg";

interface Props {
    setMenuOpen: () => void;
}
export const MainHeader: React.FC<Props> = ({ setMenuOpen }) => {
    const isUserLoggedIn = useSelector((state: RootState) => state.signIn?.signInSuccess);

    return (
        <header className="header-container container-x">
            <div className="header-hamburger">
                <HamburgerIcon onClick={setMenuOpen} />
            </div>
            <Link to={AppRoutes.Home}>
                <img src={LogoEAdviser} alt="Logo eSavjetnik" className="header-logo" />
            </Link>
            <div className="header-navigation">
                <Navigation />
            </div>
            {isUserLoggedIn ? (
                <div className="header-profile">
                    <ProfileSettings />
                </div>
            ) : (
                <Link className="header-login-button" to={AppRoutes.SignIn}>
                    Prijava
                </Link>
            )}
        </header>
    );
};
