export const RoleTypes = {
  Citizen: {
    value: "1",
    label: "Građanin",
  },
  Farmer: {
    value: "2",
    label: "Poljoprivrednik",
  },
  // Fisherman: {
  //   value: "4",
  //   label: "Ribar",
  // },
};
