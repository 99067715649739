import { useState } from "react";
import { ReactComponent as Link } from "../assets/icons/link.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import LogoEAgriculture from "../assets/images/LogoEPoljoprivreda.svg";
import LogoEAdviser from "../assets/images/LogoESavjetnik.svg";
import LogoEU from "../assets/images/LogoEU.png";
import LogoMPSR from "../assets/images/LogoMPSR.png";
import { useTitle } from "modules/common";
import { useEpoljoprivredaLogin } from "modules/auth";

interface LogoLinkItem {
    link: string;
    icon?: string;
}

const links: LogoLinkItem[] = [
    {
        link: "https://agriculture.ec.europa.eu/index_hr",
        icon: "/portalIcons/agriculture.png",
    },
    {
        link: "https://poljoprivreda.gov.hr/",
        icon: "/portalIcons/poljoprivreda.png",
    },
    {
        link: "https://fis.mps.hr/",
        icon: "/portalIcons/fis.png",
    },
    {
        link: "https://ruralnirazvoj.hr/",
        icon: "/portalIcons/ruralniRazvoj.png",
    },
    {
        link: "https://nrm.hr/clanstvo/",
        icon: "/portalIcons/nrm.png",
    },
    {
        link: "https://pitanja.mps.hr/odgovori",
        icon: "/portalIcons/pitanja.png",
    },
    {
        link: "https://www.savjetodavna.hr/",
        icon: "/portalIcons/savjetodavna.png",
    },
    {
        link: "https://stocarstvo.mps.hr/",
        icon: "/portalIcons/stocarstvo.png",
    },
    {
        link: "http://veterinarstvo.hr/",
        icon: "",
    },
    {
        link: "https://jic.mps.hr/",
        icon: "/portalIcons/jic.png",
    },
    {
        link: "https://sajamzimnice.mps.hr/",
        icon: "/portalIcons/sajamZimnice.png",
    },
    {
        link: "https://hrana-nije-otpad.mps.hr/",
        icon: "/portalIcons/hranaNijeOtpad.png",
    },
    {
        link: "https://drvna.mps.hr/",
        icon: "/portalIcons/drvna.png",
    },
    {
        link: "https://www.fadn.hr/",
        icon: "/portalIcons/fadn.png",
    },
    {
        link: "https://crobiohub.mps.hr/",
        icon: "/portalIcons/croBioHub.png",
    },
    {
        link: "https://ribarstvo.hr/",
    },
    {
        link: "https://www.apprrr.hr/",
        icon: "/portalIcons/apprrr.png",
    },
    {
        link: "https://www.hapih.hr/",
        icon: "/portalIcons/hapih.png",
    },
    {
        link: "https://www.fazos.unios.hr/",
        icon: "/portalIcons/fazos.png",
    },
    {
        link: "https://www.agr.unizg.hr/",
        icon: "/portalIcons/agr.png",
    },
    {
        link: "https://www.vef.unizg.hr/",
        icon: "/portalIcons/vef.png",
    },
    {
        link: "https://komora.hr/",
        icon: "/portalIcons/komora.png",
    },
];

const LinkItemCard = ({ link, icon }: LogoLinkItem) => {
    return (
        <div className="home-domain-link">
            <div className="home-domain-link-logo">
                {icon ? <img src={icon} alt={`Logo portala ${link}`} /> : <Link />}
            </div>
            <a href={link} rel="noreferrer" target="_blank">
                {new URL(link).hostname}
            </a>
        </div>
    );
};

export const Home = () => {
    const [searchQuery, setSearchQuery] = useState("");
    useTitle("eSavjetnik");

    useEpoljoprivredaLogin();

    const search = () => {
        if (!searchQuery) return;

        const siteQuery = links.map(item => `site:${new URL(item.link).hostname}`).join(" OR ");
        const googleQuery = `${searchQuery} ${siteQuery}`;
        const url = `https://www.google.com/search?q=${encodeURIComponent(googleQuery)}`;
        window.open(url, "_blank");
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") search();
    };

    return (
        <>
            <div className="home-container">
                <div className="home-search-area">
                    <div className="home-search-input">
                        <div className="home-search-icon">
                            <SearchIcon />
                        </div>
                        <input
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyPress={e => handleKeyPress(e)}
                            type="text"
                            value={searchQuery}
                            placeholder="Pretraživanje povezanih portala"
                        />
                    </div>
                    <div className="home-search-description">
                        <span>
                            Dobrodošli u eSavjetnik – Vaša aplikacija za digitalnu poljoprivredu.
                        </span>
                        <span>
                            Aplikacija Ministarstva poljoprivrede, šumarstva i ribarstva za pomoć u
                            poljoprivrednoj proizvodnji i lakše praćenje svojih podataka.
                        </span>
                    </div>
                </div>
                <div className="home-logo-container">
                    <div className="home-esavjetnik-logo">
                        <img src={LogoEAdviser} alt="Logo eSavjetnik" />
                    </div>
                    <div>
                        <a href={"https://poljoprivreda.gov.hr"} rel="noreferrer" target="_blank ">
                            <img src={LogoMPSR} alt="Logo MPSR" />
                        </a>
                    </div>
                    <div>
                        <img src={LogoEAgriculture} alt="Logo ePoljoprivreda" />
                    </div>
                    <div>
                        <img src={LogoEU} alt="Logo EU" />
                    </div>
                </div>
                <div className="home-domain-links-container">
                    {links.map((link, index) => (
                        <LinkItemCard key={index} {...link} />
                    ))}
                </div>
            </div>
        </>
    );
};
