import {
    Action,
    configureStore,
    ThunkAction,
    combineReducers,
    Reducer,
    AnyAction,
} from "@reduxjs/toolkit";
import {
    changePasswordReducer,
    activateAccountReducer,
    forgotPasswordReducer,
    registrationReducer,
    signInReducer,
} from "modules/auth";
import { communicationsReducer } from "modules/communication";
import {
    accountListReducer,
    activateUserReducer,
    updateRolesReducer,
    userActivityReducer,
    userAccountReducer,
    profileReducer,
    dashboardDataReducer,
    deactivateAccountReducer,
    addressReducer,
} from "modules/dashboard";
import {
    educationsReducer,
    expertAreasReducer,
    measuresReducer,
    operationTypesReducer,
    farmReducer,
} from "modules/education";
import { presentationsReducer } from "modules/presentation";
import { RootStateOrAny } from "react-redux";

export type AppDispatch = typeof store.dispatch;

const reducer = combineReducers({
    registration: registrationReducer,
    signIn: signInReducer,
    forgotPassword: forgotPasswordReducer,
    activateAccount: activateAccountReducer,
    changePassword: changePasswordReducer,
    userActivity: userActivityReducer,
    userAccount: userAccountReducer,
    activateUser: activateUserReducer,
    updateRoles: updateRolesReducer,
    accountList: accountListReducer,
    profile: profileReducer,
    address: addressReducer,
    dashboardData: dashboardDataReducer,
    deactivateAccount: deactivateAccountReducer,
    educations: educationsReducer,
    measures: measuresReducer,
    farmHolder: farmReducer,
    operationTypes: operationTypesReducer,
    expertAreas: expertAreasReducer,
    presentations: presentationsReducer,
    communications: communicationsReducer,
});

const rootReducer = (state: RootStateOrAny, action: AnyAction) => {
    if (action.type === "SignIn/signOut") return reducer(undefined, action);
    return reducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "development" ? false : true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
