import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForMpAdminFarmer = (abilityBuilder: AbilityBuilder<AppAbility>) => {
    const { can, cannot, rules } = abilityBuilder;

    cannot("view", "CourseList");
    cannot("view", "FarmList");
    cannot("update", "UserRole");

    can("view", "UserActivityModule");
    can("view", "UserAdministrationModule");
    can("view", "CommunicationModule");
    can("view", "AgroclimateStations");

    can("view", "SuperAdminFilter");
    can("view", "InternalSystemsModule");
    can("view", "Records");
    can("view", "WeatherAlarmToggle");
    can("view", "AdditionalModules");

    return rules;
};
