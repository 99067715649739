import { useQuery } from "@tanstack/react-query";
import { useStandardErrorHandler } from "modules/common";
import { createService } from "services/serviceFactory";

const documentsService = createService("documents");

const getDocumentContent = async (path: string, storageKey: string) => {
    const fullPath = `${storageKey}/${path}`;
    const response = await documentsService.get(`/documents/v1/documents/content`, {
        responseType: "blob",
        params: { path: fullPath },
    });
    return response.data;
};

export const useGetDocumentContentQuery = (path: string, storageKey: string) => {
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju dokumenta");

    return useQuery({
        queryKey: ["documentContent", path, storageKey],
        queryFn: () => getDocumentContent(path, storageKey),
        enabled: Boolean(path),
        onError: errorHandler,
    });
};
