import React from "react";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import { ReactComponent as ExclamationCircleIcon } from "assets/icons/exclamation_circle.svg";
import { Tooltip } from "modules/common";

export interface PanelMenuItem {
    label: string;
    link: string;
    fullScreen?: boolean;
    newModule?: boolean;
    internalLink?: boolean;
    isDisabled?: boolean;
}
export interface PanelMenuProps {
    items?: PanelMenuItem[];
    selectedItem?: PanelMenuItem;
    hidden?: boolean;
    onItemClicked?: (item: PanelMenuItem) => void;
}
export const PanelMenu: React.FC<PanelMenuProps> = ({
    items,
    selectedItem,
    hidden,
    onItemClicked,
}) => {
    const containerClasses = `panel-menu-container${hidden ? " panel-menu-hidden" : ""}`;

    if (hidden || !items?.length) return <div className={containerClasses} />;

    const itemElements = items.map(item => {
        const { label, newModule, fullScreen } = item;
        const itemClasses = ["panel-menu-item"];
        if (fullScreen) itemClasses.push("panel-menu-item-full-screen");
        if (selectedItem && item.link === selectedItem.link)
            itemClasses.push("panel-menu-item-selected");
        if (item.isDisabled) itemClasses.push("panel-menu-item--disabled");
        return (
            <div
                key={item.link}
                className={itemClasses.join(" ")}
                onClick={() => {
                    if (onItemClicked) onItemClicked(item);
                }}
            >
                {label}
                <div className="panel-menu-item-icon-group">
                    {newModule && (
                        <Tooltip
                            tooltipText="Novi modul"
                            tooltipPosition="left"
                            id={`new-module-tooltip-${item.label}`}
                        >
                            <ExclamationCircleIcon className="exclamation-icon" />
                        </Tooltip>
                    )}
                    {fullScreen ? (
                        <div className="circle">
                            <ChevronRightIcon />
                        </div>
                    ) : (
                        <ChevronRightIcon />
                    )}
                </div>
            </div>
        );
    });

    return <div className={containerClasses}>{itemElements}</div>;
};
