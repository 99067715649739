import { useElementRendered } from "helpers/DomHelper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

type AccessibilityStyles = {
    fontSize: number;
    dyslexia: boolean;
    lowVision: boolean;
};

type StateUpdater = React.Dispatch<React.SetStateAction<AccessibilityStyles>>;

const loadScript = (src: URL) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "nias-header";
    script.src = src.toString();
    script.defer = true;
    document.body.appendChild(script);
};

const decreaseFontSize = (setAccessibilityStyles: StateUpdater) => {
    setAccessibilityStyles(acc => {
        const newFontSize = Math.max(acc.fontSize - 5, 80);
        return { ...acc, fontSize: newFontSize };
    });
};

const increaseFontSize = (setAccessibilityStyles: StateUpdater) => {
    setAccessibilityStyles(acc => {
        const newFontSize = Math.min(acc.fontSize + 5, 120);
        return { ...acc, fontSize: newFontSize };
    });
};

const resetFontSize = (setAccessibilityStyles: StateUpdater) => {
    setAccessibilityStyles(acc => ({ ...acc, fontSize: 100 }));
};

const setDyslexia = (setAccessibilityStyles: StateUpdater, active: boolean) => {
    setAccessibilityStyles(acc => ({ ...acc, dyslexia: active, lowVision: false }));
};

const lowVis = (setAccessibilityStyles: StateUpdater, active: boolean) => {
    setAccessibilityStyles(acc => ({ ...acc, lowVision: active, dyslexia: false }));
};

const useNiasHeaderRendered = () => useElementRendered("nav#shell");

const PRODUCTION_DOMAIN = "esavjetnik.mps.hr";

const getNiasHeaderUrl = () => {
    return window.location.hostname === PRODUCTION_DOMAIN
        ? "https://eusluge-nav.gov.hr/e_gradani.aspx"
        : "https://eusluge-nav-test.gov.hr/e_gradani.aspx";
};

const NiasHeader = () => {
    const storedStateString: AccessibilityStyles = JSON.parse(
        localStorage.getItem("accessibility") ?? "null"
    );

    const [accessibilityStyles, setAccessibilityStyles] = useState<AccessibilityStyles>({
        fontSize: storedStateString?.fontSize ?? 100,
        dyslexia: storedStateString?.dyslexia ?? false,
        lowVision: storedStateString?.lowVision ?? false,
    });

    const signIn = useSelector((state: RootState) => state.signIn);
    const user = signIn?.user;
    const navToken = user?.navToken;
    const messageId = user?.messageId;
    const forPersonOib = user?.forPersonOib;
    const forLegalIps = user?.forLegalIps;
    const toLegalIps = user?.toLegalIps;
    const toLegalIzvorReg = user?.toLegalIzvorReg;
    const forLegalIzvorReg = user?.forLegalIzvorReg;
    const oib = user?.oib;

    const niasReady = useNiasHeaderRendered();

    useEffect(() => {
        document.getElementById("nias-header")?.remove();
        document.getElementById("egradjani")?.remove();

        if (!signIn.signInSuccess) return;

        let src = new URL(getNiasHeaderUrl());
        if (navToken && messageId) {
            src.searchParams.append("navToken", navToken);
            src.searchParams.append("messageId", messageId);
        }

        forPersonOib && src.searchParams.append("ForPersonOib", forPersonOib);
        forLegalIps && src.searchParams.append("ForLegalIps", forLegalIps);
        toLegalIps && src.searchParams.append("ToLegalIps", toLegalIps);
        toLegalIzvorReg && src.searchParams.append("ToLegalIzvor_reg", toLegalIzvorReg);
        forLegalIzvorReg && src.searchParams.append("ForLegalIzvor_reg", forLegalIzvorReg);

        if (oib && !forPersonOib && !forLegalIps && !toLegalIps)
            src.searchParams.append("ForPersonOib", oib);

        loadScript(src);
    }, [
        navToken,
        messageId,
        forPersonOib,
        forLegalIps,
        toLegalIps,
        toLegalIzvorReg,
        forLegalIzvorReg,
        oib,
        signIn.signInSuccess,
    ]);

    useEffect(() => {
        const root = document.documentElement;
        root.style.fontSize = `${accessibilityStyles.fontSize}%`;
        localStorage.setItem("accessibility", JSON.stringify(accessibilityStyles));
        window.dispatchEvent(
            new CustomEvent("accessibilityChange", { detail: accessibilityStyles })
        );
    }, [accessibilityStyles]);

    useEffect(() => {
        if (!niasReady) return;

        document
            .querySelector("#nonimpaired")
            ?.addEventListener("click", lowVis.bind(null, setAccessibilityStyles, true));
        document
            .querySelector("#impaired")
            ?.addEventListener("click", lowVis.bind(null, setAccessibilityStyles, false));

        document
            .querySelector("#larger")
            ?.addEventListener("click", increaseFontSize.bind(null, setAccessibilityStyles));
        document
            .querySelector("#smaller")
            ?.addEventListener("click", decreaseFontSize.bind(null, setAccessibilityStyles));
        document
            .querySelector("#default")
            ?.addEventListener("click", resetFontSize.bind(null, setAccessibilityStyles));

        document
            .querySelector("#dyslexic")
            ?.addEventListener("click", setDyslexia.bind(null, setAccessibilityStyles, false));
        document
            .querySelector("#nondyslexic")
            ?.addEventListener("click", setDyslexia.bind(null, setAccessibilityStyles, true));
    }, [niasReady, setAccessibilityStyles]);

    if (typeof window === "undefined") {
        return null;
    }

    return <div className="egradani_traka-holder" />;
};

export default NiasHeader;
