import {
    ReactEventHandler,
    DetailedHTMLProps,
    IframeHTMLAttributes,
    ReactElement,
    useRef,
} from "react";
import { OverlayState } from "../components/Overlay";
import { PanelMenuItem } from "../components/PanelMenu";
import { useIframeMessage } from "./useIframeMessage";

export interface OverlayViewProps {
    title: string;
    useLocalhost?: boolean;
    source: (string | undefined) | ((activeItem?: PanelMenuItem) => string | undefined);
    onLoad?: ReactEventHandler<HTMLIFrameElement>;
    internalLinkComponents?: Record<
        string,
        ReactElement | ((state: OverlayState, activeItem?: PanelMenuItem) => ReactElement)
    >;
    parameters?: Record<string, any>;
    dynamicHeight?: boolean;
    otherProps?: Omit<
        DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>,
        "src" | "title" | "className" | "onLoad"
    >;
}

interface UseOverlayViewProps {
    menuItems?: PanelMenuItem[];
    searchElement: JSX.Element | null;
    state: OverlayState;
    viewProps?: OverlayViewProps;
}

export const useOverlayView = ({
    menuItems,
    searchElement,
    state,
    viewProps,
}: UseOverlayViewProps) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const { selectedMenuItem, fullScreen, backButton, setIsBackButtonVisible, setShowLoading } =
        state;
    let view: JSX.Element | undefined = undefined;
    const internalLinks = viewProps?.internalLinkComponents;

    const { title, useLocalhost, source, onLoad, dynamicHeight, otherProps } = viewProps ?? {};

    useIframeMessage<number>("SET_IFRAME_HEIGHT", height => {
        if (!dynamicHeight || !ref.current) return;
        ref.current.style.height = `${height}px`;
    });

    useIframeMessage("CLEAR_IFRAME_HEIGHT", () => {
        if (!dynamicHeight || !ref.current) return;
        ref.current.style.height = "";
    });

    if (!viewProps) return {};

    if (selectedMenuItem?.internalLink && internalLinks && selectedMenuItem.link in internalLinks) {
        const viewForLink = internalLinks[selectedMenuItem.link];
        view =
            typeof viewForLink === "function" ? viewForLink(state, selectedMenuItem) : viewForLink;
        return {
            view,
            selectedLinkLabel: selectedMenuItem.label,
        };
    }

    let providedSource: string | undefined =
        typeof source === "string" || typeof source === "undefined"
            ? source
            : source(selectedMenuItem);

    if (!providedSource) return {};

    let iframeSource: string | undefined;
    if (providedSource.startsWith("http") || providedSource.startsWith("www")) {
        iframeSource = providedSource;
    } else {
        const sourceStart = useLocalhost
            ? "http://localhost:8080/"
            : `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_AGRIVI_URL}/`;
        // Get rid of duplicate slashes (sourceStart includes it at the end)
        while (providedSource.startsWith("/")) providedSource = providedSource.substring(1);
        const sourceUrl = `${sourceStart}${providedSource}`;

        const url = new URL(sourceUrl);
        if (viewProps.parameters)
            Object.entries(viewProps.parameters).forEach(([parameterName, value]) => {
                if (value !== undefined) url.searchParams.set(parameterName, value);
            });
        url.searchParams.set("isFullScreen", (fullScreen ?? false).toString());
        url.searchParams.set("hasBackButton", Boolean(backButton).toString());
        url.searchParams.set("hasSearch", Boolean(searchElement).toString());
        iframeSource = url.toString();
    }

    const fallbackTimeout = setTimeout(() => setIsBackButtonVisible(true), 500);
    view = (
        <iframe
            className="overlay-iframe"
            title={title}
            onLoad={e => {
                clearTimeout(fallbackTimeout);
                setShowLoading(false);
                if (onLoad) onLoad(e);
            }}
            src={iframeSource}
            ref={ref}
            scrolling={dynamicHeight ? "no" : undefined}
            {...otherProps}
        />
    );

    return {
        view,
        selectedLinkLabel: menuItems?.find(({ link }) => link === providedSource)?.label,
    };
};
