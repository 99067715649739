import { useTitle } from "modules/common";
import { useLocation } from "react-router-dom";
import { Overlay } from "modules/common/components/Overlay";
import { PanelMenuItem } from "modules/common/components/PanelMenu";
import { FlavescenceDoree } from "./FlavescenceDoree";
import { useMemo, useState } from "react";
import { OverlayTitleBarProps } from "modules/common/hooks/useOverlayTitleBar";
import { PlantProtectionRoutes } from "modules/common/constants/routes";
import { useRouteSearch } from "modules/common/hooks/useRouteSearch";

const menuItems: PanelMenuItem[] = [
    {
        label: "Intenzitet napada štetnika",
        link: PlantProtectionRoutes.IntensityOfPestAttacks,
        isDisabled: true,
    },
    {
        label: "Prve pojave štetnika",
        link: PlantProtectionRoutes.FirstAppearanceOfPests,
        isDisabled: true,
    },
    {
        label: "Kretanje brojnosti populacije štetnika",
        link: PlantProtectionRoutes.PestPopulationSize,
        fullScreen: true,
        isDisabled: true,
    },
    {
        label: "Karta zlatne žutice",
        link: PlantProtectionRoutes.FlavescenceDoreeMap,
        internalLink: true,
    },
    {
        label: "Bolesti i štetnici",
        link: PlantProtectionRoutes.DiseasesAndPests,
    },
    {
        label: "Preporuke o zaštiti bilja",
        link: PlantProtectionRoutes.Recommendations,
    },
    {
        label: "Sakupljanje ambalažnog otpada",
        link: PlantProtectionRoutes.CollectionOfPackagingWaste,
        fullScreen: true,
    },
];

export const PlantProtection = () => {
    useTitle("Zaštita bilja - eSavjetnik");
    const { search } = useLocation();
    const [menuItem, setMenuItem] = useState<PanelMenuItem>();
    const { debouncedSearch, searchElement } = useRouteSearch({
        routes: [PlantProtectionRoutes.DiseasesAndPests, PlantProtectionRoutes.Recommendations],
        menuItem,
    });
    const titleBarProps = useMemo<OverlayTitleBarProps | undefined>(
        () =>
            menuItem?.link.includes("karta-zlatne-zutice")
                ? {
                      aboveMenu: true,
                      text: "Karte demarkiranih područja u 2024. godini",
                  }
                : undefined,
        [menuItem?.link]
    );
    const showFullScreenButton = useMemo<boolean | undefined>(
        () =>
            menuItem?.link === PlantProtectionRoutes.IntensityOfPestAttacks ||
            menuItem?.link === PlantProtectionRoutes.FirstAppearanceOfPests,
        [menuItem?.link]
    );

    return (
        <Overlay
            backButton={!showFullScreenButton}
            fullScreenButton={showFullScreenButton}
            titleBar={titleBarProps}
            search={searchElement}
            view={{
                title: "Zaštita bilja",
                source: menuItem => `${menuItem?.link ?? ""}${search}`,
                internalLinkComponents: {
                    [PlantProtectionRoutes.FlavescenceDoreeMap]: state => (
                        <FlavescenceDoree setShowLoading={state.setShowLoading} />
                    ),
                },
                parameters: { search: debouncedSearch },
                dynamicHeight: true,
            }}
            menuItems={menuItems}
            onMenuItemChanged={setMenuItem}
        />
    );
};
