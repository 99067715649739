import { AdministrationTable, Button, OptionType, Pagination, useTitle } from "modules/common";
import { ReactComponent as DetailsIcon } from "assets/icons/details.svg";
import { useMemo, useState } from "react";
import { OuterModuleDetailsVersionModal, useOuterModuleVersions } from "modules/outerModules";
import { GetOuterModuleVersionRequest, OuterModuleVersion } from "services";
import { ValueType } from "react-select";
import { Link } from "react-router-dom";
import { AppRoutes } from "routing";

const DefaultPageSize = {
    value: "10",
    label: "10",
};

const defaultTableColumns: any[] = [
    {
        Header: "VERZIJA",
        accessor: "version",
        disableSortBy: true,
        width: 2,
    },
    {
        Header: "OPIS",
        accessor: "description",
        disableSortBy: true,
        width: 10,
    },
    {
        Header: "DATUM",
        accessor: "created",
        disableSortBy: true,
        width: 4,
        Cell: ({ row }: any) => {
            const { original } = row;
            return original?.created && new Date(original.created).toLocaleString("HR-hr");
        },
    },
];

const initialRequest: GetOuterModuleVersionRequest = {
    page: 1,
    pageSize: Number(DefaultPageSize.value),
};

export const ModuleVersionsAdministration = () => {
    useTitle("Verzije modula - eSavjetnik");
    const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
    const [request, setRequest] = useState<GetOuterModuleVersionRequest>(initialRequest);
    const [selectedVersion, setSelectedVersion] = useState<OuterModuleVersion>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: outerModuleVersions, isLoading } = useOuterModuleVersions(request);

    const tableColumns = useMemo(
        () => [
            ...defaultTableColumns,
            {
                id: "info",
                disableSortBy: true,
                width: 1,
                className: "details-icon-cell",
                Cell: ({ row }: any) => {
                    return (
                        <DetailsIcon
                            className="details-icon"
                            onClick={() => {
                                setIsModalOpen(true);
                                setSelectedVersion(row.original);
                            }}
                        />
                    );
                },
            },
        ],
        [defaultTableColumns]
    );

    const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
        setPageSize(value);
        setRequest(prev => ({
            ...prev,
            page: 1,
            pageSize: Number(value?.value ?? DefaultPageSize.value),
        }));
    };

    const handlePageClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        setRequest(prev => {
            const target = event.currentTarget ?? event.target;
            return { ...prev, page: Number(parseInt(target.id)) };
        });
    };

    const handleNexPageClick = () => {
        setRequest(prev => ({ ...prev, page: prev.page + 1 }));
    };

    const handlePrevPageClick = () => {
        setRequest(prev => ({ ...prev, page: prev.page - 1 }));
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedVersion(undefined);
    };

    return (
        <div className="module-versions-administration">
            <div className="f">
                <h1 className="module-versions-administration__heading mb-28">
                    Administracija verzija modula
                </h1>
                <Link className="f-align-right" to={AppRoutes.ModulesAdministration}>
                    <Button size="xsmall" variant="primary-ghost" isDisabled={false}>
                        Dodatni moduli
                    </Button>
                </Link>
            </div>
            <div className="table-container">
                <AdministrationTable
                    flex
                    data={outerModuleVersions?.items ?? []}
                    columns={tableColumns}
                    loading={isLoading}
                />

                {outerModuleVersions && outerModuleVersions.items.length > 0 && (
                    <Pagination
                        className="mt-24"
                        currentPage={request.page}
                        numberOfPages={Math.ceil(
                            outerModuleVersions.total /
                                parseInt(pageSize?.value ?? DefaultPageSize.value)
                        )}
                        pageNeighbours={1}
                        pageSize={pageSize ?? DefaultPageSize}
                        onPageSizeChange={handlePageSizeChange}
                        handlepageClick={handlePageClick}
                        next={handleNexPageClick}
                        prev={handlePrevPageClick}
                    />
                )}
            </div>
            <OuterModuleDetailsVersionModal
                isOpen={isModalOpen}
                handleClose={handleModalClose}
                moduleId={selectedVersion?.id}
            />
        </div>
    );
};
