import { useTitle } from "modules/common";
import { Overlay } from "modules/common/components/Overlay";
import React from "react";

export const AgroclimateStations: React.FC = () => {
    useTitle("Agroklimatske stanice - eSavjetnik");

    return (
        <Overlay
            fullScreenInitially
            view={{
                title: "Agroklimatske stanice",
                source: "agroklimatske-stanice",
            }}
        />
    );
};
