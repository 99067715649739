import { CountyFilterFields } from "modules/common";
import React from "react";
import { Filter, Checkbox } from "../";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isActive: boolean;
    showDropdown: boolean;
    value: number[];
}

export const CountyFilter: React.FC<Props> = ({
    onChange,
    onClick,
    onClear,
    isActive,
    showDropdown,
    value,
}) => {
    return (
        <Filter
            isActive={isActive}
            showDropdown={showDropdown}
            onClear={onClear}
            onClick={onClick}
            text="ŽUPANIJA"
        >
            {Object.keys(CountyFilterFields)
                .sort((_a, b) => (value.includes(CountyFilterFields[b].value) ? 1 : -1))
                .map((key, index, arr) => (
                    <div key={index}>
                        <Checkbox
                            type="checkbox"
                            value={CountyFilterFields[key].value.toString()}
                            checked={value.includes(CountyFilterFields[key].value)}
                            onChange={onChange}
                            htmlFor={key}
                            text={
                                <div className="filter__item__text">
                                    {CountyFilterFields[key].label.toLocaleUpperCase()}
                                </div>
                            }
                        />
                    </div>
                ))}
        </Filter>
    );
};
