import { decodeJwt, sessionCache, Timer, signInSuccess, tokenRenew } from "modules/auth";
import { getProfile } from "modules/dashboard";
import { AppDispatch } from "store";

export const completeSignIn = (
    dispatch: AppDispatch,
    accessToken: string,
    refreshToken: string,
    rememberMe = false
) => {
    const cache = sessionCache();
    const body = decodeJwt(accessToken);
    const refreshBody = decodeJwt(refreshToken);

    const authData = {
        userId: body.sub,
        email: body.email,
        accessToken: accessToken,
        refreshToken: refreshToken,
        role: { value: 0, label: "" },
        rememberMe: rememberMe,
        navToken: body.NavToken,
        messageId: body.MessageId,
        oib: body.oib,
        forPersonOib: body.ForPersonOib,
        forLegalIps: body.ForLegalIps,
        toLegalIps: body.ToLegalIps,
        toLegalIzvorReg: body.ToLegalIzvorReg,
        forLegalIzvorReg: body.ForLegalIzvorReg,
        ePoljoprivredaGuid: body.EPoljoprivredaGuid,
        previousLogin: body.previous_login,
        showChangeEntityDialog: false,
    };

    dispatch(signInSuccess(authData));

    cache.saveSession(rememberMe, {
        ...authData,
        expiration: body.exp,
        refreshExpiration: refreshBody.exp,
    });
    const timer = Timer.getInstance();

    timer.addHandler(async () => {
        dispatch(tokenRenew());
    });

    timer.start(body.exp);
    dispatch(getProfile());

    window.history.replaceState({}, document.title, "/");
};
