import * as React from "react";
import { Checkbox } from "../Checkbox";

interface Props {
  checked: boolean;
  size: "base" | "small";
  htmlFor: string;
  text: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioInput: React.FC<Props> = ({
  checked,
  className,
  value,
  htmlFor,
  size,
  text,
  isDisabled,
  onChange,
}) => {
  return (
    <>
      <Checkbox
        type="radio"
        className={`radio-input ${className ?? ""}`}
        checked={checked}
        value={value}
        onChange={onChange}
        htmlFor={htmlFor}
        text={text}
        isDisabled={isDisabled}
      />
    </>
  );
};
