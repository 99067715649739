import {
  Button,
  createObjectUrl,
  DatePicker,
  Input,
  Loading,
  NoResults,
  OptionType,
  Pagination,
  useTitle,
} from 'modules/common';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { DayModifiers } from 'react-day-picker';
import { ValueType } from 'react-select';
import {
  downloadPresentation,
  getPresentations,
  getPresentationsRequest,
  PresentationDeleteModal,
  PresentationFilters,
  PresentationItem,
  PresentationUploadModal,
} from 'modules/presentation';
import { ReactComponent as Add } from 'assets/icons/add.svg';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  EducationTabNavigation,
  getExpertAreas,
  getMeasures,
  getOperationTypes,
} from 'modules/education';
import { Can } from 'modules/auth';
import {
  AddOrUpdatePresentationRequest,
  downloadPresentationItem,
} from 'services';

const PAGE_NEIGHBOURS = 1;
const INPUT_DEBOUNCE_TIME = 500; //miliseconds

const DefaultPageSize = {
  value: '10',
  label: '10',
};

export const Presentations: React.FC = () => {
  useTitle('Prezentacije - eSavjetnik');
  const dispatch = useDispatch();
  const { presentations, isLoading } = useSelector(
    (state: RootState) => state.presentations
  );

  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [expertAreasFilter, setExpertAreasFilter] = useState<string[]>([]);
  const [measuresFilter, setMeasuresFilter] = useState<string[]>([]);
  const [operationsFilter, setOperationsFilter] = useState<string[]>([]);
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [date, setDate] = useState<Date | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
  const [selectedItemId, setSelectedItemId] = useState<number>();
  const [selectedItemFile, setSelectedItemFile] = useState<File | undefined>(
    undefined
  );
  const [isEditPresentation, setIsEditPresentation] = useState(false);
  const token = useSelector(
    (state: RootState) => state.signIn.user?.accessToken
  );

  const statePresentations = useSelector(
    (state: RootState) => state.presentations.presentations?.item
  );
  const currentPresentation = statePresentations?.find(
    (p) => p.id === selectedItemId
  );
  const presentation: AddOrUpdatePresentationRequest = {
    author: currentPresentation?.author ?? '',
    measures: currentPresentation?.measure.name ?? '',
    operationTypes: currentPresentation?.operationType.name ?? '',
    expertArea: currentPresentation?.expertArea.name ?? '',
    type: currentPresentation?.type ?? '',
    id: currentPresentation?.id.toString(),
    file: new File([], ''),
  };

  async function downloadItemForCurrentPresentation(id: number, token: string) {
    const response = await downloadPresentationItem(token, id);
    const file = response.data.fileAsByteArray;
    const contentType = response.data.contentType;
    const title = response.data.title;
    const extension = response.data.extension;

    const { fileName, blob } = createObjectUrl(
      file,
      contentType,
      title,
      extension
    );

    setSelectedItemFile(new File([blob], fileName));
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchTerm(value);
    setCurrentPage(1);
  }

  function handleDayClick(
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    setDate(new Date(day.toISOString()));
    setCurrentPage(1);
  }

  const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const page = Number(parseInt(event.currentTarget.id));
    setCurrentPage(page);
  };

  const handleNexPageClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPageClick = () => {
    setCurrentPage((prev) => prev - 1);
  };

  function toggleUploadModal() {
    setShowUploadModal(!showUploadModal);
  }

  function toggleDeleteModal() {
    setShowDeleteModal(!showDeleteModal);
  }

  function handleAddNewPresentation() {
    setIsEditPresentation(false);
    toggleUploadModal();
  }

  function handleEditPresentation(id: number) {
    setIsEditPresentation(true);
    toggleUploadModal();
    setSelectedItemId(id);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (
        currentPage: number,
        size: number,
        isActive: boolean,
        searchTerm: string | null,
        fileTypes: string[],
        expertAreas: string[],
        measures: string[],
        operationTypes: string[],
        date: Date | null
      ) =>
        dispatch(
          getPresentations({
            pageNumber: currentPage,
            pageSize: size,
            isActive: isActive,
            title: searchTerm,
            fileTypes,
            expertAreas,
            measures,
            operationTypes,
            createdAt: date,
          })
        ),
      INPUT_DEBOUNCE_TIME
    ),
    []
  );

  useEffect(() => {
    if (showUploadModal || showDeleteModal) {
      return;
    }

    const size = parseInt(pageSize?.value ?? DefaultPageSize.value);
    // api call is debounced, switch to loading earlier
    dispatch(getPresentationsRequest());
    debouncedSearch(
      currentPage,
      size,
      isActiveFilter,
      searchTerm,
      typeFilter,
      expertAreasFilter,
      measuresFilter,
      operationsFilter,
      date
    );

    /** Get all filters for modal data */
    dispatch(getExpertAreas());
    dispatch(getMeasures());
    dispatch(getOperationTypes());
  }, [
    currentPage,
    debouncedSearch,
    dispatch,
    pageSize?.value,
    searchTerm,
    date,
    isActiveFilter,
    typeFilter,
    expertAreasFilter,
    measuresFilter,
    operationsFilter,
  ]);

  useEffect(() => {
    if (selectedItemId && token) {
      downloadItemForCurrentPresentation(selectedItemId, token);
    }
  }, [selectedItemId, token]);

  return (
    <div className="educations-container">
      <EducationTabNavigation />
      <div className="f f-justify-between f-align-items-center mb-42">
        {/* <h1 className="educations-heading"></h1> */}
        <Can I="create" a="AddPresentation">
          <Button
            onClick={handleAddNewPresentation}
            type="button"
            variant="primary-ghost"
            size="small"
            isDisabled={false}
          >
            <Add className="mr-8" />
            <span>Dodaj novu</span>
          </Button>
        </Can>
      </div>

      <Input
        name="search"
        id="search"
        type="text"
        size="medium"
        placeholder="Pretraga"
        isValid={true}
        onChange={handleSearchChange}
        errorMessage=""
        leftIcon={<SearchIcon className="search-icon" />}
      />

      <div className="presentation__filters">
        <PresentationFilters
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          expertAreasFilter={expertAreasFilter}
          setExpertAreasFilter={setExpertAreasFilter}
          measuresFilter={measuresFilter}
          setMeasuresFilter={setMeasuresFilter}
          operationsFilter={operationsFilter}
          setOperationsFilter={setOperationsFilter}
          isActiveFilter={isActiveFilter}
          setIsActiveFilter={setIsActiveFilter}
        />

        <div className="user-activity-date-filter">
          <DatePicker onClick={handleDayClick} selectedDay={date} />
        </div>
      </div>

      {!isLoading ? (
        presentations && presentations.item.length ? (
          <>
            <h2 className="presentation__title">Lista pretrage</h2>

            {presentations?.item.map((item) => {
              return (
                <PresentationItem
                  key={item.id}
                  item={item}
                  onEdit={handleEditPresentation}
                  onDownload={(id: number) => {
                    dispatch(downloadPresentation(id));
                  }}
                  onDelete={(id: number) => {
                    setSelectedItemId(id);
                    toggleDeleteModal();
                  }}
                />
              );
            })}

            <Pagination
              className="mt-24"
              currentPage={currentPage}
              numberOfPages={Math.ceil(
                presentations.count /
                  parseInt(pageSize?.value ?? DefaultPageSize.value)
              )}
              pageNeighbours={PAGE_NEIGHBOURS}
              pageSize={pageSize ?? DefaultPageSize}
              onPageSizeChange={handlePageSizeChange}
              handlepageClick={handlePageClick}
              next={handleNexPageClick}
              prev={handlePrevPageClick}
            />
          </>
        ) : (
          <NoResults className="mt-24" />
        )
      ) : (
        <Loading />
      )}

      <PresentationUploadModal
        toggleModal={() => {
          setSelectedItemId(undefined);
          setSelectedItemFile(undefined);
          toggleUploadModal();
        }}
        selectedPresentation={presentation}
        selectedPresentationFile={selectedItemFile}
        isVisible={showUploadModal}
        isEditPresentation={isEditPresentation}
        id={selectedItemId}
      />

      <PresentationDeleteModal
        toggleModal={() => {
          setSelectedItemId(undefined);
          toggleDeleteModal();
        }}
        isVisible={showDeleteModal}
        id={selectedItemId}
      />
    </div>
  );
};
